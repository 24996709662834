import React, { useEffect, useState } from "react";
import { LargeBold, BoldLowered, Regular } from "../../Atoms/Fonts";
import * as parts from "../../Atoms/Partitions";
import BarChart from "../../Organisms/BarChart";
import Info from "../../Organisms/InfoDialogue";

//!!!!!Line 101(subject to change) divided number by 100 to give realistic value. need to rrevisit calculation.

export default function Usage() {
	const [mData, setMData] = useState([]);
	const [dData, setDData] = useState([]);
	const [subsec, setSubsec] = useState("day");
	const [av, setAv] = useState();
	const [monthAv, setMonthAv] = useState();
	const [noData, setNoData] = useState(false);
	const [noMonthData, setNoMonthData] = useState(false);

	let unchanged = true;

	useEffect(() => {
		let st = [1, 21, 31];
		let nd = [2, 22];
		let rd = [3, 23];
		const setSuffix = (d) => {
			var a = st.includes(d);
			var b = nd.includes(d);
			var c = rd.includes(d);

			if (a === true) return "st";
			else if (b === true) return "nd";
			else if (c === true) return "rd";
			else return "th";
		};

		let monthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		let json = sessionStorage.getItem("data");
		let jsonData = JSON.parse(json);
		let daily = jsonData.usage_days;

		let dailyDay = new Date().getDate();
		for (let x = 0; x < dailyDay; x++) {
			let shiftedValue = daily.shift();
			daily.push(shiftedValue);
		}
		daily.reverse();
		let monthly = jsonData.usage_months;
		let monthlyMonth = new Date().getMonth() + 1;
		for (let x = 0; x < monthlyMonth; x++) {
			let monthShiftedValue = monthly.shift();
			monthly.push(monthShiftedValue);
		}
		monthly.reverse();
		let array = [];
		if (daily.length > 1) {
			for (var i = 1; i < 31 && i < daily.length; i++) {
				array.push(daily[i]);
			}
		} else {
			console.log("No data to display");
			setNoData(true);
		}
		let arraySum = array.reduce((a, b) => a + b, 0);
		let average = arraySum / array.length;
		let averageFix = average.toFixed(2);

		let monthArr = [];
		if (monthly.length > 0) {
			for (var m = 0; m < monthly.length; m++) {
				monthArr.push(monthly[m]);
			}
		} else {
			setNoMonthData(true);
		}
		let monthlySum = monthArr.reduce((a, b) => a + b, 0);
		let monthAverage = monthlySum / monthArr.length;
		let monthFix = monthAverage.toFixed(2);

		const selectMonth = (x) => {
			return monthNames[x];
		};

		const day = (val) => {
			const today = new Date();
			const time = today.getTime();
			const subtractedDate = new Date(time - val * 24 * 60 * 60 * 1000);
			const date = subtractedDate.getDate() - 1;
			const month = subtractedDate.getMonth();
			return `${date + setSuffix(date) + " " + selectMonth(month)}`;
		};

		const month = (val) => {
			const calculatedDate = new Date();
			calculatedDate.setMonth(calculatedDate.getMonth() - val);
			const year = calculatedDate.getFullYear();
			const month = calculatedDate.getMonth();
			return `${selectMonth(month) + " " + year}`;
		};

		let dailyData = [];
		let usage_days = JSON.parse(sessionStorage.getItem("data")).usage_days;
		usage_days.reverse();
		usage_days.forEach((days, key) => {
			if (days != null) {
				dailyData.push({
					title: day(key),
					value: days,
				});
			}
		});

		let monthlyData = [];
		let usage_months = JSON.parse(sessionStorage.getItem("data")).usage_months;
		usage_months.reverse();

		usage_months.forEach((months, key) => {
			if (months != null) {
				monthlyData.push({
					title: month(key),
					value: months,
				});
			}
		});

		let newdData = JSON.parse(sessionStorage.getItem("data")).usage_days_new;
		newdData.reverse();

		const convertTimestampToDate = (timestamp) => {
			let date = new Date(timestamp._seconds * 1000);

			const day = date.getDate();
			let suffix = "";

			if (day > 3 && day < 21) suffix = "th";
			else
				switch (day % 10) {
					case 1:
						suffix = "st";
						break;
					case 2:
						suffix = "nd";
						break;
					case 3:
						suffix = "rd";
						break;
					default:
						suffix = "th";
						break;
				}

			const formatter = new Intl.DateTimeFormat("en", {
				month: "long",
				day: "numeric",
			});

			let formattedDate = formatter.format(date);
			formattedDate = formattedDate.replace(/\d+/, `${day}${suffix}`); // Replace the day number with the ordinal day

			return formattedDate;
		};

		let newDailyData = [];
		for (let i = 0; i < newdData.length; i++) {
			newDailyData.push({
				title: convertTimestampToDate(newdData[i].timestamp),
				value: newdData[i].value,
			});
		}

		let newmData = JSON.parse(sessionStorage.getItem("data")).usage_months_new;
		newmData.reverse();

		const convertTimestampToMonthYear = (timestamp) => {
			let date = new Date(timestamp._seconds * 1000);

			const formatter = new Intl.DateTimeFormat("en", {
				month: "long",
				year: "numeric",
			});

			return formatter.format(date);
		};

		let newMonthlyData = [];
		for (let i = 0; i < newmData.length; i++) {
			newMonthlyData.push({
				title: convertTimestampToMonthYear(newmData[i].timestamp),
				value: newmData[i].value,
			});
		}

		setMonthAv(monthFix);
		setAv(averageFix);
		setDData(newDailyData);
		setMData(newMonthlyData);
	}, [unchanged]);

	switch (subsec) {
		case "day":
			if (!noData) {
				return (
					<div>
						<parts.ContentTop>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<LargeBold>{av}</LargeBold>
								<BoldLowered>kWh</BoldLowered>
							</div>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<Regular color='grey'>Your average daily usage</Regular>
								<Info page='Usage' />
							</div>
						</parts.ContentTop>
						<div className='GradientMask' />
						<parts.ContentMiddle title='Daily Usage (kWh)'>
							<BarChart data={dData} source='usage' />
							<br />
							<br />
						</parts.ContentMiddle>
						<parts.ContentBottom>
							<div className='ContentLosenge'>
								<div className='LeftActive'>DAYS</div>
								<div
									className='RightInactive'
									onClick={() => {
										setSubsec("month");
									}}
								>
									MONTHS
								</div>
							</div>
						</parts.ContentBottom>
					</div>
				);
			} else {
				return (
					<div>
						<parts.ContentTop></parts.ContentTop>

						<parts.ContentMiddle>
							Data will display once there is a full day to show.
						</parts.ContentMiddle>
						<parts.ContentBottom>
							<div className='ContentLosenge'>
								<div className='LeftActive'>DAYS</div>
								<div
									className='RightInactive'
									onClick={() => {
										setSubsec("month");
									}}
								>
									MONTHS
								</div>
							</div>
						</parts.ContentBottom>
					</div>
				);
			}

		case "month":
			if (!noMonthData) {
				return (
					<div>
						<parts.ContentTop>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<LargeBold>{monthAv}</LargeBold>
								<BoldLowered>kWh</BoldLowered>
							</div>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<Regular color='grey'>Your average monthly usage</Regular>
								<Info page='Usage' />
							</div>
						</parts.ContentTop>
						<parts.ContentMiddle title='Monthly Usage (kWh)'>
							<BarChart data={mData} source='usage' />
						</parts.ContentMiddle>
						<parts.ContentBottom>
							<div className='ContentLosenge'>
								<div
									className='LeftInactive'
									onClick={() => {
										setSubsec("day");
									}}
								>
									DAYS
								</div>
								<div className='RightActive'>MONTHS</div>
							</div>
						</parts.ContentBottom>
					</div>
				);
			} else {
				return (
					<div>
						<parts.ContentTop></parts.ContentTop>
						<parts.ContentMiddle>
							Data will display once there is a full month to show.
						</parts.ContentMiddle>
						<parts.ContentBottom>
							<div className='ContentLosenge'>
								<div
									className='LeftInactive'
									onClick={() => {
										setSubsec("day");
									}}
								>
									DAYS
								</div>
								<div className='RightActive'>MONTHS</div>
							</div>
						</parts.ContentBottom>
					</div>
				);
			}
		default:
			return;
	}
}

// import React, { useState } from "react";
// import Info from "../../Organisms/InfoDialogue";

// export default function Usage() {
//   const [dayMonth, setDayMonth] = useState("day");
//   const [data, setData] = useState([]);
//   const [mData, setMData] = useState([]);

//   switch (dayMonth) {
//     case "day":
//       const handleChange = () => {
//         setDayMonth("month");
//       };

//       const showMonth = (e) => {
//         if (e === 1) {
//           return "Jan";
//         } else if (e === 2) {
//           return "Feb";
//         } else if (e === 3) {
//           return "Mar";
//         } else if (e === 4) {
//           return "Apr";
//         } else if (e === 5) {
//           return "May";
//         } else if (e === 6) {
//           return "Jun";
//         } else if (e === 7) {
//           return "Jul";
//         } else if (e === 8) {
//           return "Aug";
//         } else if (e === 9) {
//           return "Sep";
//         } else if (e === 10) {
//           return "Oct";
//         } else if (e === 11) {
//           return "Nov";
//         } else if (e === 12) {
//           return "Dec";
//         }
//       };

//       const suffix = (e) => {
//         if (e === 1 || e === 21 || e === 31) {
//           return "st";
//         } else if (e === 2 || e === 22) {
//           return "nd";
//         } else if (e === 3 || e === 23) {
//           return "rd";
//         } else return "th";
//       };

//       const handleResult = (r) => {
//         var result = JSON.parse(sessionStorage.getItem("data"));

//         var resArr = result[1].recordset;
//         var newArr = [];
//         for (var i = 1; i < resArr.length; i++) {
//           newArr.push({
//             index: i - 1,
//             month: resArr[i].month,
//             year: resArr[i].year,
//             day: resArr[i].day,
//             usage: resArr[i].usage_kwh,
//             date:
//               `${new Date(
//                 resArr[i].year + "-" + resArr[i].month + "-" + resArr[i].day
//               )}`.slice(0, 3) +
//               ", " +
//               resArr[i].day +
//               suffix(resArr[i].day) +
//               " " +
//               showMonth(resArr[i].month),
//           });
//         }
//         setData(newArr);
//       };

//       const showAvDaily = () => {
//         var thisMonth = 0;
//         for (var i = 0; i < 7; i++) {
//           thisMonth += data[i].usage;
//         }
//         var average = thisMonth / 7;
//         return <div>{average.toFixed(2)}</div>;
//       };

//       const setSize = (i, x) => {
//         if (x === "right") {
//           if (i.usage * 36 < 55) {
//             if (i.index === 0) {
//               return {
//                 width: `${i.usage * 36}vw`,
//                 marginTop: "15%",
//               };
//             } else if (i.index === data.length - 1) {
//               return {
//                 width: `${i.usage * 36 * 25}vw`,
//                 marginBottom: "15%",
//               };
//             } else
//               return {
//                 width: `${i.usage * 36}vw`,
//               };
//           } else return { width: "55vw" };
//         } else if (x === "left") {
//           if (i.index === 0) {
//             return { marginTop: "15%" };
//           } else if (i.index === data.length - 1)
//             return { marginBottom: "15%" };
//         }
//       };

//       if (data.length === 0 || data[0].usage > 30) {
//         handleResult();
//         return <div className="LoadingComponent">Loading...</div>;
//       } else {
//         return (
//           <div>
//             <Info page="Usage" />
//             <div className="ContentTop">
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                 }}
//               >
//                 <div style={{ fontSize: "6vh", fontWeight: "400" }}>
//                   {showAvDaily()}
//                 </div>
//                 <div
//                   style={{
//                     fontSize: "3vh",
//                     fontWeight: "400",
//                     paddingTop: "13%",
//                   }}
//                 >
//                   kWh
//                 </div>
//               </div>
//               <div className="GreyText">Your average daily usage</div>
//             </div>
//             <div className="GradientMask" />

//             <div className="ContentMiddle">
//               {data.map((i) => (
//                 <div className="UsageGraphContainer">
//                   <div className="UsageLeftSide" style={setSize(i, "left")}>
//                     {i.date}
//                   </div>
//                   <div className="UsageBar" style={setSize(i, "right")}>
//                     <div className="BarBack" />
//                     {i.usage.toFixed(2)}
//                   </div>
//                 </div>
//               ))}
//             </div>
//             <div className="ContentBottom">
//               <div
//                 onClick={() => {
//                   handleChange();
//                 }}
//                 className="ContentLosenge"
//               >
//                 <div className="LeftActive">DAYS</div>
//                 <div className="RightInactive">MONTHS</div>
//               </div>
//             </div>
//           </div>
//         );
//       }
//     case "month":
//       const handleChange2 = () => {
//         setDayMonth("day");
//       };

//       const showMonth2 = (e) => {
//         if (e === 1) {
//           return "January";
//         } else if (e === 2) {
//           return "February";
//         } else if (e === 3) {
//           return "March";
//         } else if (e === 4) {
//           return "April";
//         } else if (e === 5) {
//           return "May";
//         } else if (e === 6) {
//           return "June";
//         } else if (e === 7) {
//           return "July";
//         } else if (e === 8) {
//           return "August";
//         } else if (e === 9) {
//           return "September";
//         } else if (e === 10) {
//           return "October";
//         } else if (e === 11) {
//           return "November";
//         } else if (e === 12) {
//           return "December";
//         }
//       };

//       const showAvMonthly = () => {
//         if (data.length > 1) {
//           var thisMonth = 0;
//           for (var i = 0; i < 3; i++) {
//             thisMonth += data[i].usage;
//           }
//           var average = thisMonth / 3;
//           return <div>{average.toFixed(2)}</div>;
//         } else handleResult2();
//       };

//       const handleResult2 = () => {
//         var result = JSON.parse(sessionStorage.getItem("data"));

//         var resArr = result[0].recordset;
//         var newArr = [];
//         for (var i = 1; i < resArr.length; i++) {
//           newArr.push({
//             index: i - 1,
//             month: resArr[i].month,
//             year: resArr[i].year,
//             usage: resArr[i].usage_kwh,
//             date: `${showMonth2(resArr[i].month)} , ${resArr[i].year}`,
//           });
//         }
//         setMData(newArr);
//       };

//       const setSize2 = (i, x) => {
//         if (x === "right") {
//           if (i.index === 0) {
//             return {
//               width: `${i.usage / 24}vw`,
//               marginTop: "15%",
//             };
//           } else if (i.index === data.length - 1) {
//             return {
//               width: `${i.usage / 4}vw`,
//               marginBottom: "15%",
//             };
//           } else
//             return {
//               width: `${i.usage / 4}vw`,
//             };
//         } else return { width: "55vw" };
//       };

//       if (mData.length === 0) {
//         handleResult2();
//         return <div className="LoadingComponent">Loading...</div>;
//       } else {
//         return (
//           <div>
//             <Info page="Usage" />
//             <div className="ContentTop">
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                 }}
//               >
//                 <div style={{ fontSize: "6vh", fontWeight: "400" }}>
//                   {showAvMonthly()}
//                 </div>
//                 <div
//                   style={{
//                     fontSize: "3vh",
//                     fontWeight: "400",
//                     paddingTop: "13%",
//                   }}
//                 >
//                   kWh
//                 </div>
//               </div>
//               <div className="GreyText">Your average monthly usage</div>
//             </div>
//             <div className="GradientMask" />

//             <div className="ContentMiddle">
//               {mData.map((i) => (
//                 <div className="UsageGraphContainer">
//                   <div className="UsageLeftSide" style={setSize2(i, "left")}>
//                     {i.date}
//                   </div>
//                   <div className="UsageBar" style={setSize2(i, "right")}>
//                     <div className="BarBack" />
//                     {i.usage.toFixed(2)}
//                   </div>
//                 </div>
//               ))}
//             </div>
//             <div className="ContentBottom">
//               <div
//                 onClick={() => {
//                   handleChange2();
//                 }}
//                 className="ContentLosenge"
//               >
//                 <div className="LeftInactive">DAYS</div>
//                 <div className="RightActive">MONTHS</div>
//               </div>
//             </div>
//           </div>
//         );
//       }
//     default:
//   }
// }
//
