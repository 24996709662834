import { useHistory } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

function Signout() {
	let history = useHistory();

	const handleSignout = () => {
		const auth = getAuth();
		signOut(auth)
			.then(() => {
				history.push("/");
			})
			.catch((error) => {
				console.log(error);
			});
	};

	handleSignout();
	return null;
}

export default Signout;
