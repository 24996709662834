import React from "react";
import * as parts from "../../Atoms/Partitions";

export default function Emergency() {
  return (
    <div>
      <parts.ContentMiddlePlus>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <p style={{ fontSize: "2vh", textAlign: "start" }}>
            <p style={{ textAlign: "center" }}>Emergency Contact Details</p>
            <br />
            <br />
            Working Hours
            <br />
            Monday to Friday: 8.30am - 5.30pm
            <br />
            Tel: 07494 972907
            <br />
            <br />
            Out of Hours
            <br />
            Monday to Friday: 5.30pm - 8.30am,
            <br />
            Weekends and Bank Holidays
            <br />
            Tel: 0345 0503320
          </p>
        </div>
      </parts.ContentMiddlePlus>
      <parts.ContentBottom></parts.ContentBottom>
    </div>
  );
}
