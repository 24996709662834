import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FAQ } from "../Data/FAQ";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		textAlign: "start",
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
}));

export default function SimpleAccordion() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{FAQ.map((item) => (
				<Accordion key={item.question}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography className={classes.heading}>{item.question}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>{item.answer}</Typography>
					</AccordionDetails>
				</Accordion>
			))}
		</div>
	);
}
