import React, { useState } from "react";
import { getUpper } from "./Regex";

export default function SmartForm({ data, exclusions, handleResult }) {
  //exclusions is not a required field.
  if (exclusions === undefined) {
    exclusions = [];
  }
  const [formData, setFormData] = useState(data);

  const handleChange = (i, e) => {
    let newArr = [...formData];
    newArr[i].value = e;
    setFormData(newArr);
    handleResult(formData);
  };

  return formData.map((i, index) => {
    if (!exclusions.includes(getUpper(i.title))) {
      return (
        <div className="CardForm">
          <div
            style={{
              margin: "1vh",
              fontSize: "1.5vh",
              fontWeight: "400",
            }}
          >
            {getUpper(i.title)}
          </div>
          <input
            value={i.value}
            onChange={(e) => handleChange(index, e.target.value)}
            className="Input"
          />
        </div>
      );
    }
    return null;
  });
}
