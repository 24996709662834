import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import PropTypes from "prop-types";
import React from "react";
const useStyles = makeStyles({
  icon: {
    position: "absolute",
    top: "13vh",
    marginLeft: "2vh",
  },
});

var content = {
  mix: (
    <>
      This page is all about the electricity your are using right now and
      displays two pieces of information:
      This page is all about the electricity you&apos;re using and displays two
      pieces of information:
      <ul>
        <li>Your current demand</li>
        <li>And what that demand is being supplied by</li>
        <li>Your recent energy demand</li>
        <li>And how that demand is being supplied</li>
      </ul>
      “Your current demand” will tell you how much electricity your apartment is
      consuming at that particular moment.
      “Your usage” will tell you how much electricity your apartment has
      consumed in the previous full half hour.
      <br />
      <br />
      Below that, three bars tell you where your electricity is being supplied
      from. On a sunny day for example, 100% of your electricity might come from
      the solar array. During the evening or morning when the sun is not yet
      shining there might be a mix of 50% grid and 50% battery.
      Eg. If the time when you look at this value is 10:56am, this will be
      showing you data from the last completed half hour, in this case the half
      hour from 10:00am - 10:30am
      <br />
      <br />
      Below that, three bars tell you the source of that electricity. On a sunny
      day for example, most of your electricity might come from the solar array.
      At other times, there could be a mixture of solar, battery and grid
      providing the energy to meet your demand.
      <br />
      <br />
      You can use the “Refresh” button to refresh the data every 15 minutes.
    </>
  ),
  insight: (
    <>
    This page provides you with interesting insights about your electricity usage and displays three pieces of information:
    <ul>
      <li>
Your estimated savings

      </li>
<li>
Your estimated average electricity usage

</li>

<li>
Your estimated average carbon emissions

</li>

</ul>
Your estimated average electricity usage shows your average compared to the average for all homes within your SNRG SmartGrid.
<br/>
<br/>
Your estimated average carbon emissions shows your average compared to the average for a similar sized UK home.
<br/>
<br/>
This information can be viewed daily, monthly and yearly. 
<br/>
<br/>
Initially, there will be limited information displayed on this page but as SNRG Connect learns how you consume electricity more information will be displayed and it will become more and more accurate.

    </>
  ),
  usage: (
    <>
    This page is all about your electricity usage and displays two pieces of information:
    <ul>
      <li>
      Your average electricity usage

      </li>
      <li>
      Your actual electricity usage

      </li>
    </ul>

This information can be viewed daily and  monthly.
<br/>
<br/>
As the information displayed on this page uses your actual electricity usage data, it will need one full day before it can display any information.

    </>
  ),
  payments:(
  <>
    This page is all about payments and displays two pieces of information:
    <ul>
      <li>
    How much and when that will be charged to your credit/ debit card for your most recent bill
</li>
<li>
Your past payments
    </li>
    </ul>


You can use the button at the bottom of the page to go to your ‘Docs’ page to view your bills.

  </>
  )
};

function SimpleDialog(props) {
  const { onClose, open, page } = props;

  const handleClose = () => {
    onClose();
  };

  const renderContent = () => {
    if (page.page === "Mix") {
      return content.mix;
    } else if (page.page === "Insight") {
      return content.insight;
    }
    else if (page.page === "Usage") {
      return content.usage;
    }
    else if (page.page === "Payments") {
      return content.payments;
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Info</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {renderContent()}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function InfoDialogue(page) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div>
      <InfoIcon
        onClick={handleClickOpen}
        className={classes.icon}
        fontSize="small"
        color="primary"
      />
      <SimpleDialog open={open} onClose={handleClose} page={page} />
    </div>
  );
}
