import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Background from "../Resources/SNRG Background.png";
import { MaterialUIFormSubmit } from "./RegistrationForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
  },
  Dialog: {
    opacity: 0.9,
    height: "100%",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 50,
  },
}));

function SimpleDialog(props) {
  const [value, setValue] = React.useState();
  const [incorrect, setIncorrect] = React.useState(false);
  const { onClose, open } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const handleClick = () => {
    if (value === "f1961") {
      setIncorrect(false);
      handleClose();
    } else setIncorrect(true);
  };

  const renderError = () => {
    if (incorrect === true) {
      return (
        <div style={{ textAlign: "center", color: "red" }}>
          The code you have entered is incorrect, please try again.
        </div>
      );
    }
  };

  return (
    <Dialog
      fullScreen
      className={classes.Dialog}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogContent>
        <div
          className={classes.root}
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          Please enter registration code:
          <div>{renderError()}</div>
          <input
            placeholder="Code"
            type="text"
            className="Input"
            style={{ maxWidth: 400 }}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <div
            style={{ marginTop: "5vh", color: "dodgerblue", cursor: "pointer" }}
            onClick={handleClick}
          >
            SUBMIT
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

// UNUSED FUNCTION
// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"Copyright © "}
//       <Link color="inherit" href="https://oursnrg.com">
//         SNRG
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

export default function Registration() {
  const [open, setOpen] = React.useState(true);

  // const classes = useStyles();

  // const [address, setAddress] = React.useState("");

  // const handleChange = (event) => {
  //   setAddress(event.target.value);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <SimpleDialog open={open} onClose={handleClose} />
      <img
        alt="background"
        src={Background}
        style={{ height: 100, width: "100%", alignSelf: "stretch" }}
      />
      <div className="App">
        <MaterialUIFormSubmit
          formName="Sample Form Submit"
          formDescription="This is sample form using Material UI."
        />
      </div>
      {/* <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} onSubmit={""} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="mobileNumber"
                variant="outlined"
                required
                fullWidth
                id="mobileNumber"
                label="Telephone"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" className={classes.formControl} style={{width:190}}>
            <InputLabel id="demo-simple-select-outlined-label">Address</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={address}
                onChange={handleChange}
                label="Address"
            >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={1}>Flat 1, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={2}>Flat 2, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={3}>Flat 3, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={4}>Flat 4, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={5}>Flat 5, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={6}>Flat 6, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={7}>Flat 7, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={8}>Flat 8, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={9}>Flat 9, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={10}>Flat 10, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={11}>Flat 11, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={12}>Flat 12, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={13}>Flat 13, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={14}>Flat 14, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={15}>Flat 15, 54 The Avenue - NN175EE</MenuItem>
          <MenuItem value={16}>Flat 16, 54 The Avenue - NN175EE</MenuItem>
        </Select>
        </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Confirm Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I confirm that I have checked all my details and that they are correct."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="#" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container> */}
    </div>
  );
}
