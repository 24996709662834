import { Button, Icon, TextField, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import React, { useReducer } from "react";
import { useHistory } from "react-router-dom";
import { EnvSettings } from "../deploymentconfig";

export function MaterialUIFormSubmit(props) {
  let history = useHistory();

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },

    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      borderRadius: 50,
    },

    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    root: {
      padding: theme.spacing(3, 2),
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
  }));

  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstname: "",
      lastname: "",
      email: "",
      telephone: "",
      address: "",
      password: "",
    }
  );


  // this need to be changed we need to add in a full system on the back end to show the relavent list of houses that a person is able to select from 

  const addresses = [
    "Flat 1, 54 The Avenue NN175EE",
    "Flat 2, 54 The Avenue  NN175EE",
    "Flat 3, 54 The Avenue  NN175EE",
    "Flat 4, 54 The Avenue  NN175EE",
    "Flat 5, 54 The Avenue  NN175EE",
    "Flat 6, 54 The Avenue  NN175EE",
    "Flat 7, 54 The Avenue  NN175EE",
    "Flat 8, 54 The Avenue  NN175EE",
    "Flat 9, 54 The Avenue  NN175EE",
    "Flat 10, 54 The Avenue  NN175EE",
    "Flat 11, 54 The Avenue  NN175EE",
    "Flat 12, 54 The Avenue  NN175EE",
    "Flat 13, 54 The Avenue  NN175EE",
    "Flat 14, 54 The Avenue  NN175EE",
    "Flat 15, 54 The Avenue  NN175EE",
    "Flat 16, 54 The Avenue  NN175EE",
  ];

  const handleSubmit = (evt) => {
    evt.preventDefault();

    let data = { formInput };

    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data.formInput);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const EnvUrl =
      EnvSettings.LiveServiceUrl + "/ConnectAdmin/ConnectAdmin/ConnectCreateUser";

    const SecondTrigger = 
      EnvSettings.LiveServiceUrl + "/ConnectAdmin/ConnectAdmin/CreateUserAccountNumber"

    fetch(EnvUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .then(fetch(SecondTrigger, requestOptions))
      .catch((error) => console.log("error", error));

    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  const classes = useStyles();

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <br />
          <br />
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  variant="outlined"
                  required
                  fullWidth
                  label="First Name"
                  autoFocus
                  name="firstname"
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Last Name"
                  autoComplete="lname"
                  name="lastname"
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Telephone"
                  name="telephone"
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ width: 190 }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Address
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={formInput.address}
                    name="address"
                    onChange={handleInput}
                    label="Address"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={addresses[0]}>
                      Flat 1, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[1]}>
                      Flat 2, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[2]}>
                      Flat 3, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[3]}>
                      Flat 4, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[4]}>
                      Flat 5, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[5]}>
                      Flat 6, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[6]}>
                      Flat 7, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[7]}>
                      Flat 8, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[8]}>
                      Flat 9, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[9]}>
                      Flat 10, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[10]}>
                      Flat 11, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[11]}>
                      Flat 12, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[12]}>
                      Flat 13, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[13]}>
                      Flat 14, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[14]}>
                      Flat 15, 54 The Avenue - NN175EE
                    </MenuItem>
                    <MenuItem value={addresses[15]}>
                      Flat 16, 54 The Avenue - NN175EE
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Confirm Password"
                  type="password"
                  autoComplete="current-password"
                  onChange={handleInput}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value="allowExtraEmails"
                      required
                      color="primary"
                    />
                  }
                  on
                />
                <p
                  className="Roboto"
                  style={{ fontWeight: "bold", textAlign: "left" }}
                >
                  I confirm that my details are correct
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value="allowExtraEmails"
                      required
                      color="primary"
                    />
                  }
                />
                <p
                  className="Roboto"
                  style={{ fontWeight: "bold", textAlign: "left" }}
                >
                  I confirm that I have read the{" "}
                  <a href="./Terms" target="_blank">
                    Terms and Conditions
                  </a>
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value="allowExtraEmails"
                      required
                      color="primary"
                    />
                  }
                />
                <p
                  className="Roboto"
                  style={{ fontWeight: "bold", textAlign: "left" }}
                >
                  I confirm that I have read the{" "}
                  <a href="./Privacy" target="_blank">
                    Privacy Policy
                  </a>
                </p>
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Sign Up <Icon className={classes.rightIcon}>send</Icon>
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
}
