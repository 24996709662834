var Billing = {
	billingDate: 23,
	dueDate: 3,
	pastBills: [
		{ Month: 8, Year: 2021, Price: 42.22 },
		{ Month: 7, Year: 2021, Price: 45.36 },
		{ Month: 6, Year: 2021, Price: 53.54 },
		{ Month: 5, Year: 2021, Price: 47.43 },
		{ Month: 4, Year: 2021, Price: 61.43 },
		{ Month: 3, Year: 2021, Price: 63.21 },
		{ Month: 2, Year: 2021, Price: 56.43 },
	],
};

var FAQ = [
	{
		question: "What is my SNRG SmartGrid?",
		answer: (
			<>
				<p>
					Your SNRG SmartGrid is a communal energy system that generates and
					stores solar energy from the sun. SNRG's SmartGrid combines solar
					panels (PV) and communal battery storage with SNRG's unique smart
					control system and home energy app.
				</p>
				<p>
					SNRG operates the system and buys in energy (from energy suppliers)
					when there isn't enough energy available from the solar PV or the
					battery.
				</p>
			</>
		),
	},
	{
		question: "What do I do in an electrical emergency?",
		answer: (
			<>
				<p>
					If there is no electricity, check whether other properties on your
					street are affected. If they are affected, then contact your
					Distribution Network Operator (DNO). You can find your DNO{" "}
					<a href='https://www.energynetworks.org/customers/find-my-network-operator'>
						here
					</a>
					.
				</p>
				<p>
					Currently, your network operator is GTC. Visit their website{" "}
					<a href='https://www.gtc-uk.co.uk/'>here</a>.
				</p>
				<p>
					In case of power failure in the first instance, you can contact them
					and keep track of progress by visiting the live incident updates
					provided on their website{" "}
					<a href='https://www.gtc-uk.co.uk/live-incidents/'>here</a>.
				</p>

				<p>
					If it is just your apartment that is affected, then please check your
					own consumer unit to see if any switches have tripped.
				</p>
				<p>
					If some or all the apartments are affected, or there are no tripped
					switches on your consumer unit, then please call one of the following
					numbers:
				</p>
				<ul>
					<li>
						<strong>Normal working hours:</strong>
					</li>
					<ul>
						<li>Monday to Friday: 8.30am - 5.30pm</li>
						<li>Tel: 0203 488 6686</li>
					</ul>
					<li>
						<strong>Out of hours:</strong>
					</li>
					<ul>
						<li>
							Monday to Friday: 5.30pm - 8.30am inc weekends/bank holidays
						</li>
						<li>Tel: 0345 050 3320</li>
					</ul>
				</ul>
			</>
		),
	},
	{
		question: "How do I register for a SNRG Connect account? ",
		answer: (
			<>
				<p>By following these three easy steps:</p>
				<ol>
					<li>
						<p>
							Go to{" "}
							<a href='https://snrgconnect.com/registration'>
								snrgconnect.com/registration
							</a>{" "}
							or scan the QR code in your welcome letter.
						</p>
					</li>
					<li>
						<p>Enter the registration code provided in your welcome letter.</p>
					</li>
					<li>
						<p>Complete your account information.</p>
					</li>
				</ol>
				<p>
					If you are unable to access the SNRG Connect app, please email us at{" "}
					<a href='mailto:support@oursnrg.com'>support@oursnrg.com</a>, and we
					will get back to you within one working day.
				</p>
			</>
		),
	},
	{
		question: " How do I get in contact with SNRG? ",
		answer: (
			<>
				<p>
					Through your SNRG Connect account, follow these three easy steps to
					get in touch:
				</p>
				<ol>
					<li>
						<p>Click the 'Menu' button at the top of your web app.</p>
					</li>
					<li>
						<p>
							Select 'Support' from the drop-down and then click the 'Contact'
							icon at the bottom of the page.
						</p>
					</li>
					<li>
						<p>
							Complete the 'Contact Us' form and we'll get back to you within
							two working days.
						</p>
					</li>
				</ol>
				<p>
					If you are unable to access the SNRG Connect app, please email us with
					your name and address at{" "}
					<a href='mailto:support@oursnrg.com'>support@oursnrg.com</a>, and we
					will get back to you within two working days, or call us on <br />
					0203 488 6686.
				</p>
			</>
		),
	},
	{
		question: "How do I reset my password?",
		answer: (
			<>
				Through the SNRG Connect app. Follow these steps:
				<br />
				<p style={{ marginLeft: "3vw" }}>
					i. Click the ‘menu’ button at the top of your app.
				</p>
				<p style={{ marginLeft: "3vw" }}>
					ii. Then select ‘Account’ and press the ‘Password’ icon at the bottom
					of the page.
				</p>
				<p style={{ marginLeft: "3vw" }}>
					iii. Press the ‘Reset Password’ button and follow the prompts on the
					email that will be sent to the account associated with your SNRG
					account.
				</p>
			</>
		),
	},
	{
		question: "I’ve forgotten my password. What should I do?",
		answer: (
			<>
				The best way to resolve this is on the SNRG Connect login page. Follow
				these steps:
				<br />
				<p style={{ marginLeft: "3vw" }}>
					i. Enter the following URL www.snrgconnect.com
				</p>
				<p style={{ marginLeft: "3vw" }}>ii. Click ‘Forgotten password’</p>
				<p style={{ marginLeft: "3vw" }}>
					iii. Enter the email address associated with your SNRG account and a
					password reset email will be sent there.
				</p>
				<p style={{ marginLeft: "3vw" }}>
					iv. Follow the steps on the email to rest your password
				</p>
			</>
		),
	},
	{
		question:
			"I'm having problems with my SNRG Connect app. How do I fix them?",
		answer: (
			<>
				<p>Follow the below steps:</p>
				<p>
					<strong>For Apple Users:</strong>
				</p>
				<ol>
					<li>
						<p>Open the Settings app on your iPhone.</p>
					</li>
					<li>
						<p>
							Scroll down to your browser app settings and select it, most
							likely Safari.
						</p>
					</li>
					<li>
						<p>Scroll down and click "Clear History and Website Data".</p>
					</li>
					<li>
						<p>
							This should not affect your saved passwords on this or any other
							site.
						</p>
					</li>
				</ol>
				<p>
					<strong>For Android Users:</strong>
				</p>
				<ol>
					<li>
						<p>Open Settings.</p>
					</li>
					<li>
						<p>Go to Apps/App Manager.</p>
					</li>
					<li>
						<p>Choose the browser you are currently using (e.g., Chrome).</p>
					</li>
					<li>
						<p>
							If your device runs on Marshmallow or later, tap Storage &gt;
							Clear Cache.
						</p>
					</li>
					<li>
						<p>
							If this is not completely successful, tap 'Clear Data', but only
							if you have tried the above step first, as this may result in
							password loss.
						</p>
					</li>
				</ol>
				<p>
					If you're still experiencing problems, contact us through the SNRG
					Connect app. If you are unable to access your SNRG Connect app, email
					us at <a href='mailto:support@oursnrg.com'>support@oursnrg.com</a>.
				</p>
			</>
		),
	},
	{
		question: "How do I access my electricity supply agreement?",
		answer: (
			<>
				<p>
					Through your SNRG account, follow these three easy steps to access
					your agreement:
				</p>
				<ol>
					<li>
						<p>Click the 'Menu' button at the top of your web app.</p>
					</li>
					<li>
						<p>
							Select 'Account' and click the 'Documents' icon at the bottom of
							the page.
						</p>
					</li>
					<li>
						<p>Your Supply Agreement will be displayed.</p>
					</li>
				</ol>
				<p>
					If you'd like a paper copy, please call us on 0203 488 6686 or email
					us at <a href='mailto:support@oursnrg.com'>support@oursnrg.com</a>.
				</p>
			</>
		),
	},
	{
		question: "What is a Smart Energy Fair Share Credit?",
		answer: (
			<>
				The Fair Share Credit is the residents’ share of the savings made by the
				operation of the microgrid, solar PV and battery energy storage system.
				This is calculated for the communal network of 16 residences and shared
				equally between each property. This is applied as a monthly credit to
				your bill and shown separately.
			</>
		),
	},

	{
		question: "When will I get my bill?",
		answer: (
			<p>
				SNRG provides bills on a monthly cycle, meaning every cycle starts on
				the 1st day of each month and ends on the last day of each month. You
				will receive your bill upto 10 working days after the end of each
				billing cycle.
				<br />
				<br />
				If you move out before the end of the month you will receive your final
				bill as per normal upto 10 working days after the end of your last
				billing cycle.
			</p>
		),
	},
	{
		question: "How do I pay my bill?",
		answer: (
			<>
				<p>
					At the end of a billing cycle, we will email your bill, with a link to
					make the payment. This payment link is provided by the payments
					platform called Stripe. Once you click on the link, it will take you
					to a secured payment page, where you can make the payment by using
					either your credit card, debit card, or Google Pay.
				</p>
				<p>
					Alternatively, if you wish, you can use our phone payment service. If
					you want to learn more about that, please write to us at{" "}
					<a href='mailto:support@oursnrg.com'>support@oursnrg.com</a>.
				</p>
			</>
		),
	},
	{
		question: "How do I set-up and update my payment method?",
		answer: (
			<>
				SNRG has partnered with a company called ‘Stripe’ to offer safe and
				secure bill payments. To set up your bill payments do the following:
				<br />
				<p style={{ marginLeft: "3vw" }}>
					i. Click the ‘menu’ button at the top of your app
				</p>
				<p style={{ marginLeft: "3vw" }}>
					ii. Select ‘Account’ and press the ‘Payment’ icon at the bottom of the
					page
				</p>
				<p style={{ marginLeft: "3vw" }}>
					iii. Click the ‘Go to Stripe’ button and wait for the secure stripe
					payment portal to load
				</p>
				<p style={{ marginLeft: "3vw" }}>
					iv. Click “+ Add payment method” and enter card details
				</p>
				Please note at this point SNRG only accepts card payments via Stripe.
				Please contact us to discuss other payment options if required.
			</>
		),
	},
	{
		question: "What do I do if I’m unable to pay my bill?",
		answer: (
			<p>
				We understand that it’s not always easy to pay your bill. So let us know
				if you’re finding it difficult and we’ll work together on a plan that
				suits your needs. And if you already owe us money, we’ll discuss options
				for you to pay it back in manageable instalments.
			</p>
		),
	},
	{
		question: "Can I switch suppliers?",
		answer: (
			<p>
				If you wish to switch suppliers and stop using SNRG, you will need to
				leave your SNRG SmartGrid and you will lose the SmartGrid benefits. You
				will need to arrange to have a new meter installed, which may also
				involve paying for an electrician. Please contact SNRG at
				support@oursnrg.com to request a more detailed explanation of how to
				leave. We would also suggest that if you are renting the property, check
				if you need permission from your landlord prior to changing supplier.
			</p>
		),
	},
	{
		question: "Will I still gain the SmartGrid benefits if I switch suppliers?",
		answer: (
			<p>
				Although it is not recommended to leave your SNRG SmartGrid, should you
				wish to leave you will receive the SmartGrid Leaver Credit. <br /> This
				equates to 1/16th of any income generated by exporting solar energy to
				the public grid, minus the SmartGrid Charge (£7.50 per month). To
				clarify, if you switch to another supplier, you won’t continue to pay
				the £7.50 per month, this figure is just used for the calculation. If
				this figure is negative you won’t pay SNRG anything. If it is positive
				then this is what you will receive.
			</p>
		),
	},

	{
		question: "What do I need to do if I’m moving out?",
		answer: (
			<p>
				Simply contact us through the SNRG app, or email us, or call us, and
				confirm the last day you want to have electricity supplied. You are
				required to give us at least 2 working days notice.
			</p>
		),
	},
];

export { Billing, FAQ };
