import React from "react";
import * as parts from "../../Atoms/Partitions";

export default function Contact() {
  return (
    <div>
      <parts.ContentFull>
        <iframe
          title="Contact Form"
          src="https://forms.monday.com/forms/embed/cb1110dc13710ad66632124b0e6bad69?r=use1"
          height="100%"
          width="100%"
          style={{ border: 0, margin: 0, scrollbarWidth: "thin" }}
        />
      </parts.ContentFull>
    </div>
  );
}
