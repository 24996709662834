import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import * as icons from "../Resources/icons/svg-gray/icons";
import BG from "../Resources/SNRG Background.png";
import Bills from "./Energy Pages/Bills";
import Insight from "./Energy Pages/Insight";
import Mix from "./Energy Pages/Mix";
import Usage from "./Energy Pages/Usage";
import { useHistory } from "react-router-dom";
import Background from "../Resources/SNRG Background.png";
import Logo from "../Resources/LOGO.png";
import HeaderLogo from "../Resources/LOGO.png";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function Energy() {
	let history = useHistory();

	const [content, setContent] = useState("mix");
	const activeCol = "#D62E6E";
	const inactiveCol = "#8D90A7";
	const [insightCol, setInsightCol] = useState(inactiveCol);
	const [mixCol, setMixCol] = useState(activeCol);
	const [usageCol, setUsageCol] = useState(inactiveCol);
	const [billsCol, setBillsCol] = useState(inactiveCol);
	const MenuItems = [
		{ name: "Energy" },
		{ name: "Account" },
		{ name: "Support" },
		{ name: "Signout" },
	];

	const showContent = () => {
		if (content === "mix") return <Mix />;
		// else if (content === "insight") return <Insight />;
		else if (content === "usage") return <Usage />;
		else if (content === "bills") return <Bills />;
		else return;
	};

	const chooseContent = (e) => {
		// if (e === "insight") {
		// 	setContent("insight");
		// 	// document.getElementById("insight").style.color = activeCol;
		// 	document.getElementById("mix").style.color = inactiveCol;
		// 	document.getElementById("usage").style.color = inactiveCol;
		// 	document.getElementById("bills").style.color = inactiveCol;
		// 	setInsightCol(activeCol);
		// 	setMixCol(inactiveCol);
		// 	setUsageCol(inactiveCol);
		// 	setBillsCol(inactiveCol);
		// } else 
		if (e === "mix") {
			setContent("mix");
			// document.getElementById("insight").style.color = inactiveCol;
			document.getElementById("mix").style.color = activeCol;
			document.getElementById("usage").style.color = inactiveCol;
			document.getElementById("bills").style.color = inactiveCol;
			// setInsightCol(inactiveCol);
			setMixCol(activeCol);
			setUsageCol(inactiveCol);
			setBillsCol(inactiveCol);
		} else if (e === "usage") {
			setContent("usage");
			// document.getElementById("insight").style.color = inactiveCol;
			document.getElementById("mix").style.color = inactiveCol;
			document.getElementById("usage").style.color = activeCol;
			document.getElementById("bills").style.color = inactiveCol;
			// setInsightCol(inactiveCol);
			setMixCol(inactiveCol);
			setUsageCol(activeCol);
			setBillsCol(inactiveCol);
		} else if (e === "bills") {
			setContent("bills");
			// document.getElementById("insight").style.color = inactiveCol;
			document.getElementById("mix").style.color = inactiveCol;
			document.getElementById("usage").style.color = inactiveCol;
			document.getElementById("bills").style.color = activeCol;
			// setInsightCol(inactiveCol);
			setMixCol(inactiveCol);
			setUsageCol(inactiveCol);
			setBillsCol(activeCol);
		}
	};

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleMenuItem = (n) => {
		if (n === "Account") {
			history.push("/Account");
		} else if (n === "Energy") {
			handleClose();
		} else if (n === "Signout") {
			history.push("/Signout");
		} else if (n === "Support") {
			history.push("/Support");
		}
	};

	return (
		<div className='EnergyContainer'>
			<img src={BG} className='BGHeader' alt='Bg' height={80} width={400} />

			{/* HEADER */}
			<div className='EnergyHeader'>
				<div className='HeaderLeft'>
					<img src={HeaderLogo} height='16' width='65' alt='logo'></img>
				</div>
				<div className='HeaderMiddle'>ENERGY</div>
				<div className='HeaderRight' onClick={handleClickOpen}>
					MENU
				</div>
			</div>
			{/* CONTENT */}
			<div className='EnergyContent'>{showContent()}</div>
			{/* FOOTER */}
			<div className='EnergyFooter'>
				<div
					className='FooterButtons'
					id='mix'
					style={{ color: activeCol }}
					onClick={() => {
						chooseContent("mix");
					}}
				>
					<icons.mix col={mixCol} />
					<div style={{ marginTop: 10, fontWeight: "400" }}>Mix</div>
				</div>
				{/* <div
					className='FooterButtons'
					id='insight'
					onClick={() => {
						chooseContent("insight");
					}}
				>
					<icons.insight col={insightCol} />
					<div style={{ marginTop: 10, fontWeight: "400" }}>Insight</div>
				</div> */}

				<div
					className='FooterButtons'
					id='usage'
					onClick={() => {
						chooseContent("usage");
					}}
				>
					<icons.usage col={usageCol} />
					<div style={{ marginTop: 10, fontWeight: "400" }}>Usage</div>
				</div>
				<div
					className='FooterButtons'
					id='bills'
					onClick={() => {
						chooseContent("bills");
					}}
				>
					<icons.bills col={billsCol} />
					<div style={{ marginTop: 10, fontWeight: "400" }}>Payments</div>
				</div>
			</div>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<div className='Login'>
					<img src={Background} className='LoginBG' alt='BG' height={2000} />
					<img src={Logo} className='Logo3' alt='Logo' height={20} />
					<div className='MenuContainer'>
						{MenuItems.map((item) => (
							<div
								key={item.name}
								onClick={() => handleMenuItem(item.name)}
								className='MenuItem'
							>
								{item.name}
							</div>
						))}
						<div onClick={handleClose} style={{ color: "white", margin: 20 }}>
							Close
						</div>
					</div>
				</div>
			</Dialog>
		</div>
	);
}

// {
//   <div className="menutest">
//     <img src={Background} className="menubg" alt="BG" height={3000} />

//     <div onClick={() => handleClose()}>X</div>
//     <div className="MenuContainer">
//       {MenuItems.map((item) => (
//         <div onclick={() => handleMenuItem(item.name)} className="MenuItem">
//           {item.name}
//         </div>
//       ))}
//     </div>
//   </div>;
// }
