const firebaseConfig = {
	apiKey: "AIzaSyCZTu2pt8ccghXRwMj149mfVNBtl80kioU",
	authDomain: "snrg-api-live.firebaseapp.com",
	databaseURL:
		"https://snrg-api-live-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "snrg-api-live",
	storageBucket: "gs://snrg-api-live.appspot.com",
	messagingSenderId: "297874484428",
	appId: "1:297874484428:web:87399a65caca00aae66669",
	measurementId: "G-J34ZMMH9JY",
	getUnitDataURL:
		"https://us-central1-snrg-test-env.cloudfunctions.net/api/getUnitData",
};

export { firebaseConfig };

// Live
//   apiKey: "AIzaSyCZTu2pt8ccghXRwMj149mfVNBtl80kioU",
//   authDomain: "snrg-api-live.firebaseapp.com",
//   databaseURL: "https://snrg-api-live-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "snrg-api-live",
//   storageBucket: "snrg-api-live.appspot.com",
//   messagingSenderId: "297874484428",
//   appId: "1:297874484428:web:87399a65caca00aae66669",
//   measurementId: "G-J34ZMMH9JY",
// getUnitDataURL:
//     "https://us-central1-snrg-test-env.cloudfunctions.net/api/getUnitData",

// Dev
// apiKey: "AIzaSyC_dywaJuXHxiVPuh7Fgg8O5G1BQuRmBJI",
// authDomain: "snrg-api.firebaseapp.com",
// projectId: "snrg-api",
// storageBucket: "snrg-api.appspot.com",
// messagingSenderId: "447355352232",
// appId: "1:447355352232:web:bdc8e64f58faeca3f2be9d",
// measurementId: "G-3QBS6TC8BC",
// getUnitDataURL:
//     "https://us-central1-snrg-test-env.cloudfunctions.net/api/getUnitData",
