import React from "react";
import * as parts from "../../Atoms/Partitions";
// import PdfDownload from "../../Organisms/PdfDownload";

export default function Documents() {
	// const link = JSON.parse(sessionStorage.getItem("link"));
	// const url = `${link.link}`;
	return (
		<div>
			<parts.ContentMiddlePlus>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						paddingTop: "3vh",
					}}
				>
					When available, you can view your bills here.
					{/* <PdfDownload /> */}
				</div>
			</parts.ContentMiddlePlus>
			<parts.ContentBottom>
				{/* <a className='ContentLosenge' href={url}>
					PAY NOW
				</a> */}
			</parts.ContentBottom>
		</div>
	);
}
