import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import * as parts from "../../Atoms/Partitions";

export default function Password() {
	const [clicked, setClicked] = useState(false);

	useEffect(() => {
		if (clicked === true) {
			setTimeout(() => {
				setClicked(false);
			}, 5000);
		}
	}, [clicked]);

	const renderNotice = () => {
		if (clicked === true) {
			return (
				<div className='BodyText'>
					You will shortly receive an Email to update your password.
				</div>
			);
		}
	};

	const handleClick = () => {
		const auth = getAuth();
		var user = sessionStorage.getItem("user");
		setClicked(true);
		return auth.sendPasswordResetEmail(user);
	};
	return (
		<div>
			<parts.ContentTop />
			<parts.ContentMiddle>
				<div className='cont'>
					<div className='BodyText'>
						Use the button below to be sent an email link to reset your pasword.
					</div>
					<div className='Button' onClick={() => handleClick()}>
						Reset Password
					</div>
					{renderNotice()}
				</div>
			</parts.ContentMiddle>
			<parts.ContentBottom />
		</div>
	);
}
