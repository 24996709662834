import React from "react";
import * as parts from "../../Atoms/Partitions";
import Accordion from "../../Organisms/Accordion";

export default function FAQ() {
  return (
    <div>
      <parts.ContentFull>
        <Accordion />
      </parts.ContentFull>
    </div>
  );
}
