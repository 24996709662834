import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import React, { useState } from "react";
import * as icons from "../Resources/icons/svg-gray/icons";
import BG from "../Resources/SNRG Background.png";
import FAQ from "./Support Pages/FAQ";
import Emergency from "./Support Pages/Emergency";
import Contact from "./Support Pages/Contact";
import { useHistory } from "react-router-dom";
import Background from "../Resources/SNRG Background.png";
import Logo from "../Resources/LOGO.png";
import HeaderLogo from "../Resources/LOGO.png";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function Support() {
	let history = useHistory();

	const [content, setContent] = useState("profile");
	const activeCol = "#D62E6E";
	const inactiveCol = "#8D90A7";
	const [profileCol, setProfileCol] = useState(activeCol);
	const [paymentCol, setPaymentCol] = useState(inactiveCol);
	const [contactCol, setContactCol] = useState(inactiveCol);
	const MenuItems = [
		{ name: "Energy" },
		{ name: "Account" },
		{ name: "Support" },
		{ name: "Signout" },
	];

	const showContent = () => {
		if (content === "profile") return <FAQ />;
		else if (content === "payment") return <Emergency />;
		else if (content === "contact") return <Contact />;
		else return;
	};

	const chooseContent = (e) => {
		if (e === "profile") {
			setContent("profile");
			document.getElementById("profile").style.color = activeCol;
			document.getElementById("payment").style.color = inactiveCol;
			document.getElementById("contact").style.color = inactiveCol;
			setProfileCol(activeCol);
			setPaymentCol(inactiveCol);
			setContactCol(inactiveCol);
		} else if (e === "payment") {
			setContent("payment");
			document.getElementById("profile").style.color = inactiveCol;
			document.getElementById("payment").style.color = activeCol;
			document.getElementById("contact").style.color = inactiveCol;
			setProfileCol(inactiveCol);
			setPaymentCol(activeCol);
			setContactCol(inactiveCol);
		} else if (e === "contact") {
			setContent("contact");
			document.getElementById("profile").style.color = inactiveCol;
			document.getElementById("payment").style.color = inactiveCol;
			document.getElementById("contact").style.color = activeCol;
			setProfileCol(inactiveCol);
			setPaymentCol(inactiveCol);
			setContactCol(activeCol);
		}
	};

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleMenuItem = (n) => {
		if (n === "Account") {
			history.push("/Account");
		} else if (n === "Energy") {
			history.push("/Energy");
		} else if (n === "Signout") {
			history.push("/Signout");
		} else if (n === "Support") {
			handleClose();
		}
	};

	return (
		<div className='EnergyContainer'>
			<img src={BG} className='BGHeader' alt='Bg' height={80} width={400} />

			{/* HEADER */}
			<div className='EnergyHeader'>
				<div className='HeaderLeft'>
					<img src={HeaderLogo} height='16' width='65' alt='logo'></img>
				</div>
				<div className='HeaderMiddle'>SUPPORT</div>
				<div className='HeaderRight' onClick={handleClickOpen}>
					MENU
				</div>
			</div>
			{/* CONTENT */}
			<div className='EnergyContent'>{showContent()}</div>
			{/* FOOTER */}
			<div className='EnergyFooter'>
				<div
					className='FooterButtons'
					id='profile'
					style={{ color: activeCol }}
					onClick={() => {
						chooseContent("profile");
					}}
				>
					<icons.faq col={profileCol} />
					<div style={{ marginTop: 10, fontWeight: "400" }}>FAQ</div>
				</div>
				<div
					className='FooterButtons'
					id='contact'
					style={{ color: inactiveCol }}
					onClick={() => {
						chooseContent("contact");
					}}
				>
					<icons.contact col={contactCol} />
					<div style={{ marginTop: 10, fontWeight: "400" }}>Contact</div>
				</div>
				<div
					className='FooterButtons'
					id='payment'
					onClick={() => {
						chooseContent("payment");
					}}
				>
					<icons.emergency col={paymentCol} />
					<div style={{ marginTop: 10, fontWeight: "400" }}>Emergency</div>
				</div>
			</div>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<div className='Login'>
					<img src={Background} className='LoginBG' alt='BG' height={2000} />
					<img src={Logo} className='Logo3' alt='Logo' />
					<div className='MenuContainer'>
						{MenuItems.map((item) => (
							<div
								key={item.name}
								onClick={() => handleMenuItem(item.name)}
								className='MenuItem'
							>
								{item.name}
							</div>
						))}
						<div onClick={handleClose} style={{ color: "white", margin: 20 }}>
							Close
						</div>
					</div>
				</div>
			</Dialog>
		</div>
	);
}
