import React from "react";

export default function LoadingDots() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        color: "white",
        alignItems: "center",
        zIndex: 10,
      }}
    >
      <br />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "3vh",
        }}
      >
        <div className="dot-1" />
        <div className="dot-2" />
        <div className="dot-3" />
        <div className="dot-4" />
      </div>
      optimising your daily data
    </div>
  );
}
