import React from "react";
import axios from "axios";
import * as parts from "../../Atoms/Partitions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { EnvSettings } from "../../deploymentconfig";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		justifyContent: "center",
		height: "100%",
		alignItems: "center",
		"& > * + *": {
			marginLeft: theme.spacing(2),
		},
	},
	Dialog: {
		opacity: 0.8,
	},
}));

function SimpleDialog(props) {
	const { onClose, open } = props;
	const classes = useStyles();

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog
			fullScreen
			className={classes.Dialog}
			onClose={handleClose}
			aria-labelledby='simple-dialog-title'
			open={open}
		>
			<DialogContent>
				<div className={classes.root}>
					<CircularProgress size={80} />
				</div>
			</DialogContent>
		</Dialog>
	);
}

SimpleDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};

export default function Payments() {
	let userData = JSON.parse(sessionStorage.getItem("userData"));
	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleClick = () => {
		setOpen(true);
		setTimeout(() => {
			handleClose();
		}, 3000);
		var data = JSON.stringify({
			customerId: userData.stripeID,
		});

		const urlprefix =
			EnvSettings.LiveServiceUrl + "/Billing/Billing/RedirectToStripe";
		var config = {
			method: "post",
			url: urlprefix,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios(config)
			.then(function (response) {
				window.location.href = response.data.url;
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<div>
			<parts.ContentTop></parts.ContentTop>
			<parts.ContentMiddle>
				<div className='cont'>
					<div className='BodyText'>
						{" "}
						To make changes to your payment methods, please use the button below
						to be redirected to our 3rd party payment utility
					</div>
					<div className='Button' onClick={() => handleClick()}>
						<SimpleDialog open={open} onClose={handleClose} />
						manage payment details
					</div>
				</div>
			</parts.ContentMiddle>

			<parts.ContentBottom></parts.ContentBottom>
		</div>
	);
}
