import styled from "styled-components";

const CardLink = styled.a`
	display: flex;
	font-family: "Roboto", sans-serif;
	font-weight: bolder;
	height: 10vh;
	border: solid gainsboro 1px;
	border-radius: 10px;
	margin: 1vh;
	text-decoration: none;
	color: black;
	width: 90vw;
`;

const Pdf = ({ col }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='24px'
			viewBox='0 0 24 24'
			width='24px'
			fill={col}
		>
			<path d='M0 0h24v24H0z' fill='none' />
			<path d='M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z' />
		</svg>
	);
};

const Icon = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Info = styled.div`
	flex: 4;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
`;

const Title = styled.div`
	display: flex;
	justify-content: space-between;
	width: 90%;
	margin: 1vw;
`;
const Description = styled.div`
	display: flex;
	justify-content: space-between;
	width: 90%;
	font-size: 90%;
	color: lightslategray;
	margin: 1vw;
`;

const PdfDownload = () => {
	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<CardLink href='https://storage.googleapis.com/snrg-api-live.appspot.com/General/Privacy%20Policy.pdf'>
				<Icon>
					<Pdf />
				</Icon>
				<Info>
					<Title>Privacy Policy</Title>
					<Description>View</Description>
				</Info>
			</CardLink>
			<CardLink href='https://storage.googleapis.com/snrg-api-live.appspot.com/General/TermsAndConditions.pdf'>
				<Icon>
					<Pdf />
				</Icon>
				<Info>
					<Title>Terms & Conditions</Title>
					<Description>View</Description>
				</Info>
			</CardLink>
			<CardLink href='https://storage.googleapis.com/snrg-api-live.appspot.com/General/SNRG%20Supply%20Agreement.pdf'>
				<Icon>
					<Pdf />
				</Icon>
				<Info>
					<Title>Supply Agreement</Title>
					<Description>View</Description>
				</Info>
			</CardLink>
		</div>
	);
};

export default PdfDownload;
