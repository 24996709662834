const bills = ({ col }) => {
	return (
		<svg
			width='25'
			height='25'
			viewBox='0 0 250 250'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect width='250' height='250' fill='white' />
			<g clipPath='url(#clip0)'>
				<path
					d='M96.8 101.014H153C158 101.014 162.1 96.856 162.1 91.785C162.1 86.714 158 82.5558 153 82.5558H96.8C91.8 82.5558 87.7 86.714 87.7 91.785C87.7 96.9574 91.8 101.014 96.8 101.014Z'
					fill={col}
				/>
				<path
					d='M195.5 125.761H96.8C91.8 125.761 87.7 129.919 87.7 134.99C87.7 140.061 91.8 144.219 96.8 144.219H195.5C200.5 144.219 204.6 140.061 204.6 134.99C204.6 129.919 200.6 125.761 195.5 125.761Z'
					fill={col}
				/>
				<path
					d='M244.5 1.11598C241.2 -0.405318 237.4 0.203201 234.7 2.63728L209.2 25.5582L183.7 2.33302C180.3 -0.810998 175 -0.810998 171.5 2.33302L146 25.4568L120.5 2.33302C117.1 -0.810998 111.8 -0.810998 108.3 2.33302L82.8 25.5582L57.2 2.63728C54.5 0.203201 50.7 -0.405318 47.4 1.11598C44.1 2.63728 42 5.88271 42 9.53383V174.037H11C6.00002 174.037 1.90002 178.195 1.90002 183.367V205.883C1.90002 229.615 20.6 249.088 43.8 249.899H204.2C204.9 249.899 205.6 249.899 206.3 249.899C230.3 249.899 249.7 230.122 249.7 205.883V9.53383C249.9 5.98413 247.8 2.63728 244.5 1.11598ZM20.2 205.883V192.597H163V205.883C163 215.416 166 224.24 171.1 231.441H45.4C31.5 231.441 20.2 219.98 20.2 205.883ZM231.7 205.883C231.7 219.98 220.4 231.441 206.5 231.441C192.6 231.441 181.3 219.98 181.3 205.883V183.367C181.3 178.297 177.2 174.138 172.2 174.138H60.3V30.1221L76.8 44.8279C80.3 47.972 85.5 47.972 88.9 44.8279L114.4 21.7042L139.9 44.8279C143.3 47.972 148.6 47.972 152.1 44.8279L177.6 21.7042L203.1 44.8279C206.5 47.972 211.8 47.972 215.2 44.8279L231.7 30.1221V205.883Z'
					fill={col}
				/>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect
						width='247.9'
						height='250'
						fill='white'
						transform='translate(2)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
const insight = ({ col }) => {
	return (
		<svg
			width='25'
			height='25'
			viewBox='0 0 250 250'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0)'>
				<rect width='250' height='250' fill='white' />
				<g clipPath='url(#clip1)'>
					<path
						d='M185.2 25.7694C169.2 9.82471 148 1 125.5 1C103 1 81.7 9.82471 65.7 25.7694C49.8 41.714 41 62.9735 41 85.637C41 101.582 45.4 117.025 53.8 130.462C61 141.895 70.8 151.521 82.4 158.441V208.682C82.4 231.947 101.3 251 124.6 251H125.7C149 251 167.9 232.047 167.9 208.682V158.742C179.6 151.923 189.6 142.195 196.9 130.663C205.4 117.125 209.9 101.582 209.9 85.5367C209.9 62.9735 201.1 41.8143 185.2 25.7694ZM150.5 210.587C149.5 223.423 138.7 233.651 125.7 233.651H124.6C111.6 233.651 100.8 223.423 99.8 210.587H150.5ZM99.8 193.238V178.798H150.7V193.238H99.8ZM150.6 161.349H99.8V153.327C99.8 150.017 98 147.109 95.1 145.605C72.5 134.073 58.4 111.108 58.4 85.637C58.4 67.6867 65.4 50.8395 78.1 38.1039C90.8 25.3682 107.6 18.3486 125.6 18.3486C143.6 18.3486 160.4 25.3682 173.1 38.0036C185.8 50.7393 192.8 67.5864 192.8 85.5367C192.8 98.2724 189.2 110.707 182.5 121.437C175.9 131.866 166.6 140.29 155.6 145.805C152.7 147.31 150.8 150.218 150.8 153.527L150.6 161.349Z'
						fill={col}
					/>
					<path
						d='M141.2 78.4168H125.5L140.8 54.5499C142.1 52.6446 142.5 50.2379 142 48.0317C141.5 45.7252 140.2 43.8199 138.2 42.6165C134.2 40.0092 128.8 41.2126 126.2 45.2238L102.4 82.428C100.7 85.0353 100.6 88.5451 102.1 91.2527C103.6 94.0606 106.5 95.7653 109.7 95.7653H125.5L106.9 125.248C104.3 129.259 105.6 134.674 109.6 137.181C111 138.084 112.6 138.485 114.2 138.485C117.2 138.485 119.9 136.981 121.5 134.474L148.5 91.7541C150.2 89.1468 150.3 85.637 148.8 82.9294C147.3 80.1215 144.4 78.4168 141.2 78.4168Z'
						fill={col}
					/>
				</g>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect width='250' height='250' fill='white' />
				</clipPath>
				<clipPath id='clip1'>
					<rect
						width='168.9'
						height='250'
						fill='white'
						transform='translate(41 1)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
const mix = ({ col }) => {
	return (
		<svg
			width='25'
			height='25'
			viewBox='0 0 250 250'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect width='250' height='250' fill='white' />
			<g clipPath='url(#clip0)'>
				<path
					d='M62.4 98.1964H8.9C4 98.1964 0 102.204 0 107.114V240.982C0 245.892 4 249.9 8.9 249.9H62.4C67.3 249.9 71.3 245.892 71.3 240.982V107.114C71.3 102.204 67.3 98.1964 62.4 98.1964ZM53.5 232.064H17.8V116.032H53.5V232.064Z'
					fill={col}
				/>
				<path
					d='M151.4 0H98C93.1 0 89.1 4.00802 89.1 8.91784V240.982C89.1 245.892 93.1 249.9 98 249.9H151.5C156.4 249.9 160.4 245.892 160.4 240.982V8.91784C160.4 4.00802 156.4 0 151.4 0ZM142.6 232.064H106.9V17.8357H142.6V232.064Z'
					fill={col}
				/>
				<path
					d='M240.5 71.4429H187C182.1 71.4429 178.1 75.4509 178.1 80.3607V241.082C178.1 245.992 182.1 250 187 250H240.5C245.4 250 249.4 245.992 249.4 241.082V80.3607C249.5 75.4509 245.4 71.4429 240.5 71.4429ZM231.6 232.064H196V89.2786H231.7V232.064H231.6Z'
					fill={col}
				/>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect width='249.5' height='250' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
const usage = ({ col }) => {
	return (
		<svg
			width='25'
			height='25'
			viewBox='0 0 250 250'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect width='250' height='250' fill='white' />
			<g clipPath='url(#clip0)'>
				<path
					d='M113.7 250C112.6 250 111.5 249.8 110.5 249.4C106.3 247.9 103.8 243.6 104.4 239.2L116.8 155.6H54.4C51 155.6 47.9 153.8 46.2 150.8C44.5 147.8 44.6 144.2 46.3 141.3L129.2 4.5C131.5 0.699998 136.1 -0.900002 140.3 0.499998C144.5 1.9 147.1 6.1 146.6 10.5L135.9 97.6L196.5 96.8C199.9 96.8 203.1 98.6 204.8 101.6C206.5 104.6 206.4 108.2 204.6 111.2L121.6 245.6C119.9 248.4 116.8 250 113.7 250ZM71.1 136.8H127.7C130.4 136.8 133 138 134.8 140.1C136.6 142.2 137.4 144.9 137 147.6L129.7 196.8L179.6 115.9L125.4 116.6C122.7 116.6 120.1 115.5 118.3 113.5C116.5 111.5 115.7 108.8 116 106.1L122.7 51.9L71.1 136.8Z'
					fill={col}
				/>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect
						width='160.9'
						height='250'
						fill='white'
						transform='translate(45)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
const contact = ({ col }) => {
	return (
		<svg
			width='25'
			height='25'
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 250 250'
		>
			<title>contact</title>
			<path
				fill={col}
				d='M247.61,235.8l-22.22-21.93A103,103,0,0,0,194.3,55.64,103.55,103.55,0,0,0,157.17,15.5,102.9,102.9,0,0,0,24.61,169.7L2.39,191.63A8,8,0,0,0,8,205.34l54.45.26A103,103,0,0,0,147.09,250l94.94-.49a8,8,0,0,0,5.59-13.71Zm-145-30h.29A103,103,0,0,0,203.47,81a86.87,86.87,0,0,1,5.11,127.46,8,8,0,0,0,0,11.36l13.94,13.75-37.16.19-38.31.2A86.76,86.76,0,0,1,82.91,205.7ZM41.38,175.66a8,8,0,0,0,0-11.36A86.89,86.89,0,1,1,189.8,102.91a87.25,87.25,0,0,1-25.59,61.55c-16.46,16.33-38.93,25.3-63.36,25.3h-.78l-72.63-.35Z'
			/>
			<path
				fill={col}
				d='M59.47,82h87.37a8,8,0,0,0,0-16H59.47a8,8,0,0,0,0,16Z'
			/>
			<path
				fill={col}
				d='M59.47,111.17h87.37a8,8,0,0,0,0-16H59.47a8,8,0,0,0,0,16Z'
			/>
			<path
				fill={col}
				d='M154.85,132.28a8,8,0,0,0-8-8H59.47a8,8,0,0,0,0,16h87.37A8,8,0,0,0,154.85,132.28Z'
			/>
		</svg>
	);
};

const emergency = ({ col }) => {
	return (
		<svg
			width='25'
			height='25'
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 250 250'
		>
			<title>emergency</title>
			<path
				fill={col}
				d='M224.79,182.44h-8.23V162.1a91.55,91.55,0,0,0-183.11,0v20.35H25.21a12.48,12.48,0,0,0-12.47,12.47v42.62A12.48,12.48,0,0,0,25.21,250H224.79a12.48,12.48,0,0,0,12.47-12.47V194.91A12.48,12.48,0,0,0,224.79,182.44ZM51.84,162.1a73.16,73.16,0,0,1,146.31,0v20.35H51.84Zm167,69.51H31.14V200.84H218.86Z'
			/>
			<path
				fill={col}
				d='M46.1,72.86A9.2,9.2,0,0,0,58.86,59.61l-22-21.23A9.2,9.2,0,1,0,24.05,51.63Z'
			/>
			<path
				fill={col}
				d='M197.52,75.44a9.17,9.17,0,0,0,6.38-2.57l22-21.23a9.2,9.2,0,0,0-12.76-13.25l-22,21.23a9.2,9.2,0,0,0,6.38,15.83Z'
			/>
			<path
				fill={col}
				d='M124.54,49h.18a9.2,9.2,0,0,0,9.19-9l.58-30.6A9.2,9.2,0,0,0,116.09,9l-.58,30.6A9.2,9.2,0,0,0,124.54,49Z'
			/>
		</svg>
	);
};

const faq = ({ col }) => {
	return (
		<svg
			width='25'
			height='25'
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 250 250'
		>
			<title>faq</title>
			<path
				fill={col}
				d='M121.17,167.59a13.11,13.11,0,0,0,0,26.21,13.11,13.11,0,1,0,0-26.21Z'
			/>
			<path
				fill={col}
				d='M123.38,68.36c-23,0-33.53,13.62-33.53,22.81,0,6.64,5.62,9.7,10.21,9.7,9.19,0,5.45-13.11,22.81-13.11,8.51,0,15.32,3.74,15.32,11.57,0,9.19-9.53,14.47-15.15,19.23-4.94,4.25-11.4,11.23-11.4,25.87,0,8.85,2.38,11.4,9.36,11.4,8.34,0,10-3.74,10-7,0-8.85.17-14,9.53-21.27,4.6-3.57,19.06-15.15,19.06-31.15S145.17,68.36,123.38,68.36Z'
			/>
			<path
				fill={col}
				d='M125,0A124.93,124.93,0,0,0,0,125V240.23A9.77,9.77,0,0,0,9.77,250H125A125,125,0,0,0,125,0Zm0,230.47H19.53V125A105.47,105.47,0,1,1,125,230.47Z'
			/>
		</svg>
	);
};

const profile = ({ col }) => {
	return (
		<svg
			width='25'
			height='25'
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 250 250'
		>
			<title>profile</title>
			<path
				fill={col}
				d='M123.44,122.2a59.12,59.12,0,0,0,43.2-17.9,59.13,59.13,0,0,0,17.9-43.2,59.13,59.13,0,0,0-17.9-43.2A59.13,59.13,0,0,0,123.44,0a59.12,59.12,0,0,0-43.2,17.9,59.12,59.12,0,0,0-17.9,43.2,59.13,59.13,0,0,0,17.9,43.2A59.14,59.14,0,0,0,123.44,122.2Zm0-104.33A41.3,41.3,0,0,1,154,30.54,41.3,41.3,0,0,1,166.67,61.1,41.3,41.3,0,0,1,154,91.66a41.29,41.29,0,0,1-30.56,12.66A41.3,41.3,0,0,1,92.88,91.66,41.29,41.29,0,0,1,80.22,61.1,41.29,41.29,0,0,1,92.88,30.54h0A41.29,41.29,0,0,1,123.44,17.88Z'
			/>
			<path
				fill={col}
				d='M229.07,191.2a148.46,148.46,0,0,0-2-15.76,124.39,124.39,0,0,0-3.89-15.88,79,79,0,0,0-6.58-14.93,56.8,56.8,0,0,0-10-13,44.54,44.54,0,0,0-14.5-9.12,49.9,49.9,0,0,0-18.44-3.34c-2.9,0-5.57,1.1-10.59,4.37-2.9,1.89-6.28,4.07-10,6.47a56,56,0,0,1-12.62,5.55,48.8,48.8,0,0,1-30.77,0A55.74,55.74,0,0,1,97,130c-3.76-2.4-7.14-4.58-10.05-6.48-5-3.27-7.69-4.37-10.58-4.37a49.85,49.85,0,0,0-18.44,3.35,44.49,44.49,0,0,0-14.51,9.12,56.79,56.79,0,0,0-10,13,79.24,79.24,0,0,0-6.58,14.94,124.84,124.84,0,0,0-3.88,15.88,147.34,147.34,0,0,0-2,15.77c-.33,4.74-.5,9.68-.5,14.68,0,13.36,4.27,24.2,12.69,32.22S52.36,250,65.64,250H184.36c13.28,0,24.21-4,32.52-11.9s12.69-18.86,12.69-32.22C229.57,200.87,229.4,195.93,229.07,191.2ZM74.85,137c.51.29,1.27.74,2.34,1.44,3.06,2,6.49,4.21,10.19,6.57a73.29,73.29,0,0,0,16.73,7.48,66.67,66.67,0,0,0,41.77,0A73.39,73.39,0,0,0,162.61,145c4-2.54,7.3-4.69,10.18-6.57,1.08-.7,1.84-1.16,2.35-1.44a31.34,31.34,0,0,1,10.34,2.06,26.75,26.75,0,0,1,8.72,5.41,39,39,0,0,1,6.83,9,61.16,61.16,0,0,1,5.1,11.55,106.54,106.54,0,0,1,3.32,13.59,131.08,131.08,0,0,1,1.78,13.79v.06c.3,4.3.46,8.83.46,13.44,0,8.39-2.34,14.69-7.14,19.27s-11.54,7-20.19,7H65.64c-8.66,0-15.26-2.28-20.19-7s-7.14-10.88-7.14-19.27c0-4.6.15-9.12.45-13.44a129.8,129.8,0,0,1,1.78-13.85A106.87,106.87,0,0,1,43.86,165,61.41,61.41,0,0,1,49,153.45a39.12,39.12,0,0,1,6.83-9,26.72,26.72,0,0,1,8.73-5.41A31.31,31.31,0,0,1,74.85,137Z'
			/>
		</svg>
	);
};

const payment = ({ col }) => {
	return (
		<svg
			width='25'
			height='25'
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 250 250'
		>
			<title>payment</title>
			<path
				fill={col}
				d='M249.59,139.92,229.07,33.44a22.4,22.4,0,0,0-26.2-17.73l-161,31a22.4,22.4,0,0,0-17.73,26.2l1.65,8.57H22.37A22.4,22.4,0,0,0,0,103.88V212.33A22.4,22.4,0,0,0,22.37,234.7h164a22.4,22.4,0,0,0,22.37-22.37V170.59l23.15-4.46A22.4,22.4,0,0,0,249.59,139.92ZM22.37,96.38h164a7.51,7.51,0,0,1,7.5,7.5v15.35h-179V103.88A7.51,7.51,0,0,1,22.37,96.38Zm164,123.44h-164a7.51,7.51,0,0,1-7.5-7.5V134.11h179v78.22A7.51,7.51,0,0,1,186.34,219.83ZM229,151.52l-20.34,3.92V103.88a22.4,22.4,0,0,0-22.37-22.37H40.93L38.74,70.13a7.51,7.51,0,0,1,5.94-8.78l161-31a7.5,7.5,0,0,1,8.78,5.94L235,142.74A7.51,7.51,0,0,1,229,151.52Z'
			/>
			<path
				fill={col}
				d='M108.09,154.57H41.53a7.44,7.44,0,1,0,0,14.87h66.55a7.44,7.44,0,0,0,0-14.87Z'
			/>
			<rect
				fill={col}
				x='136.66'
				y='178.94'
				width='36.46'
				height='25.33'
				rx='5.84'
				ry='5.84'
			/>
		</svg>
	);
};

const password = ({ col }) => {
	return (
		<svg
			width='25'
			height='25'
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 250 250'
		>
			<title>password</title>
			<path
				fill={col}
				d='M194.82,85.63h-5.34V64.48a64.48,64.48,0,1,0-129,0V85.63H55.18A27.7,27.7,0,0,0,27.51,113.3v109A27.7,27.7,0,0,0,55.18,250H194.82a27.7,27.7,0,0,0,27.67-27.67v-109A27.7,27.7,0,0,0,194.82,85.63ZM79,64.48a46,46,0,1,1,91.9,0V85.63H79ZM204,222.33a9.15,9.15,0,0,1-9.14,9.14H55.18A9.15,9.15,0,0,1,46,222.33v-109a9.15,9.15,0,0,1,9.14-9.14H194.82A9.15,9.15,0,0,1,204,113.3Z'
			/>
			<path
				fill={col}
				d='M125,131.84a25.87,25.87,0,0,0-9.27,50v25.27a9.27,9.27,0,1,0,18.53,0V181.86a25.87,25.87,0,0,0-9.27-50ZM125,165a7.34,7.34,0,1,1,7.34-7.34A7.35,7.35,0,0,1,125,165Z'
			/>
		</svg>
	);
};

const documents = ({ col }) => {
	return (
		<svg
			width='25'
			height='25'
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 250 250'
		>
			<title>docs</title>
			<path
				fill={col}
				d='M226.91,88.18,142,2.47A8.33,8.33,0,0,0,136,0h-78A37.42,37.42,0,0,0,20.68,37.37V212.63A37.42,37.42,0,0,0,58.05,250h133.9a37.42,37.42,0,0,0,37.37-37.37V94A8.33,8.33,0,0,0,226.91,88.18ZM143.42,27.6l60.11,60.65H163.59a20.2,20.2,0,0,1-20.18-20.18Zm48.53,205.75H58.05a20.74,20.74,0,0,1-20.72-20.72V37.37A20.74,20.74,0,0,1,58.05,16.65h68.71V68.06a36.88,36.88,0,0,0,36.83,36.83h49.08V212.63A20.74,20.74,0,0,1,191.95,233.35Z'
			/>
			<path
				fill={col}
				d='M74.82,113.28h24a8.33,8.33,0,1,0,0-16.65h-24a8.33,8.33,0,1,0,0,16.65Z'
			/>
			<path
				fill={col}
				d='M162.82,138.44h-88a8.33,8.33,0,0,0,0,16.65h88a8.33,8.33,0,0,0,0-16.65Z'
			/>
			<path
				fill={col}
				d='M162.82,180.26h-88a8.33,8.33,0,1,0,0,16.65h88a8.33,8.33,0,0,0,0-16.65Z'
			/>
		</svg>
	);
};

const pdf = ({ col }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='24px'
			viewBox='0 0 24 24'
			width='24px'
			fill={col}
		>
			<path d='M0 0h24v24H0z' fill='none' />
			<path d='M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z' />
		</svg>
	);
};

export {
	bills,
	insight,
	mix,
	usage,
	contact,
	emergency,
	faq,
	profile,
	payment,
	password,
	documents,
	pdf,
};
