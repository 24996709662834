const LargeBold = (props) => {
  return (
    <div className="LargeBold" style={{ color: props.color }}>
      {props.children}
    </div>
  );
};
const Regular = (props) => {
  return <div style={{ color: props.color }}>{props.children}</div>;
};
const BoldLowered = (props) => {
  return (
    <div className="BoldLowered" style={{ color: props.color }}>
      {props.children}
    </div>
  );
};
const SubTitle = (props) => {
  return (
    <div className="Subtitle" style={{ color: props.color }}>
      {props.children}
    </div>
  );
};

export { LargeBold, Regular, BoldLowered, SubTitle };
