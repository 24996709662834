import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import * as React from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../Resources/LOGO.png";
import Background from "../../Resources/SNRG Background.png";
import { EnvSettings } from "../../deploymentconfig";
import LoadingDots from "../../Toolbox/LoadingDots";
import { firebaseConfig } from "../../ApiKeys";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export var currentUserProfile = "";

const LoadingPage = () => {
	let history = useHistory();

	const getUserData = async (i) => {
		const db = getFirestore();
		const user = i.replace(/\./g, "");
		const docRef = doc(db, "UserProfiles", user);

		const docSnapshot = await getDoc(docRef);
		if (docSnapshot.exists()) {
			sessionStorage.setItem("userData", JSON.stringify(docSnapshot.data()));
			const unitID = JSON.stringify(docSnapshot.data().UnitId);
			getData(unitID - 1000);
		} else {
			console.log("No such document!");
		}
	};

	const urlprefix = EnvSettings.LiveServiceUrl;

	const getDocs = async (i) => {
		//Receives User Documents. Daily fetch sufficient
		let urls = [urlprefix + "/Azure/Azure/ListFilesFromAzureForCustomer"];

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = "";

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		//THE ABSOLUTE DON FOR FETCHING MULTIPLE APIs
		try {
			Promise.all(
				urls.map((url) => fetch(url, requestOptions).then((res) => res.json()))
			).then((json) => {
				sessionStorage.setItem("docs", JSON.stringify(json));
				history.push("/Energy");
			});
		} catch (error) {
			console.error(error);
		}
	};

	const getData = async (unitNumber) => {
		try {
			const response = await fetch(
				`https://us-central1-snrg-test-env.cloudfunctions.net/main/processLegacyData?unitNumber=${unitNumber}`
			);
			if (response.ok) {
				const data = await response.json();
				sessionStorage.setItem("data", JSON.stringify(data));
				getDocs();
			} else {
				console.log("No such document!");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	React.useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (!user) {
				history.push("/Login");
			} else {
				currentUserProfile = user.email;
				sessionStorage.setItem("user", currentUserProfile);
				getUserData(currentUserProfile);
			}
		});
	});

	return (
		<div className='Login'>
			<img src={Background} className='LoginBG' alt='BG' height={2000} />
			<img src={Logo} className={"Logo3"} alt='Logo' />
			<LoadingDots />
		</div>
	);
};

export default LoadingPage;
