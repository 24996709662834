import { initializeApp } from "firebase/app";
import React from "react";
import Div100vh from "react-div-100vh";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { firebaseConfig } from "./ApiKeys";
import "./CSS/Account.css";
import "./CSS/App.css";
import "./CSS/Atoms.css";
import "./CSS/Energy.css";
import "./CSS/Graphs.css";
import "./CSS/Login.css";
import AccountSettings from "./Pages/Account/AccountSettings";
import Loading from "./Pages/Account/Loading";
import Login from "./Pages/Account/Login";
import Signout from "./Pages/Account/Signout";
import Energy from "./Pages/Energy";
import NoMatch from "./Pages/NoMatch";
import Registration from "./Pages/Registration";
import Support from "./Pages/Support";
import Test from "./Pages/Test";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";

function App() {
	initializeApp(firebaseConfig);
	// if (screenHeight < screenWidth) {
	//   return (
	//     <div className="App">
	//       <div className="ErrorScreen">
	//         <img src={Background} className="AppBG" alt="BG" />
	//         This App is not yet optimised for landscape screens.
	//         <br /> Please use a mobile device in portrait mode.
	//         <br /> Sorry for any inconvenience.
	//       </div>
	//     </div>
	//   );
	// } else {
	return (
		<Div100vh>
			<div className='App'>
				<Router>
					<Switch>
						<Route exact path='/' component={Loading} />
						<Route exact path='/Login' component={Login} />
						<Route exact path='/Signout' component={Signout} />
						<Route exact path='/Energy' component={Energy} />
						<Route exact path='/Support' component={Support} />
						<Route exact path='/Account' component={AccountSettings} />
						<Route exact path='/Registration' component={Registration} />
						<Route exact path='/Test' component={Test} />
						<Route exact path='/Terms' component={Terms} />
						<Route exact path='/Privacy' component={Privacy} />

						<Route component={NoMatch} />
					</Switch>
				</Router>
			</div>
		</Div100vh>
	);
}

export default App;
