import React from "react";
import * as parts from "../../Atoms/Partitions";
import PdfDownload from "../../Organisms/PdfDownload";

export default function Documents() {
	//gather the data
	let d = JSON.parse(sessionStorage.getItem("docs"))[0].message;

	//set the datastructure for the .map function
	let data = [];
	for (var i = 0; i < d.length; i++) {
		data.push({
			id: d[i].name,
			size: d[i].size,
			date: d[i].created,
		});
	}

	//open pdf in new tab onClick
	// const handleClick = (i) => {
	// 	var data = JSON.stringify({
	// 		fileName: i,
	// 		containerName: "temptestdata",
	// 	});

	// 	var config = {
	// 		method: "post",
	// 		mode: "no-cors",
	// 		withCredentials: false,
	// 		url: "https://us-central1-snrg-api.cloudfunctions.net/Azure/Azure/GetFilesFromAzure",
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 		},
	// 		data: data,
	// 	};

	// 	axios(config)
	// 		.then((response) => {
	// 			axios({
	// 				// Run the Axios call, then nest a secondary call to the Link provided by back-end
	// 				url: response.data.message, // set URL
	// 				method: "GET",
	// 				withCredentials: false, // avoids issues with Azure as we already ran through the credential sin node
	// 				responseType: "blob", // important -> sets the internel app storage
	// 			}).then((response) => {
	// 				const url = window.URL.createObjectURL(new Blob([response.data])); // create blob sotrage from url data
	// 				const link = document.createElement("a");
	// 				link.href = url;
	// 				link.setAttribute("download", `file.pdf`);
	// 				document.body.appendChild(link);
	// 				link.click();
	// 			});
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// };

	// const getDate = (i) => {
	// 	return i.slice(0, 10);
	// };

	return (
		<div>
			<parts.ContentFull>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						paddingTop: "3vh",
					}}
				>
					<PdfDownload />
				</div>
			</parts.ContentFull>
		</div>
	);
}
