import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { LargeBold, BoldLowered, Regular } from "../../Atoms/Fonts";
import * as parts from "../../Atoms/Partitions";
import { EnvSettings } from "../../deploymentconfig";
import BarChart from "../../Organisms/BarChart";
import Info from "../../Organisms/InfoDialogue";
import { el } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  Dialog: {
    opacity: 0.8,
  },
}));

function SimpleDialog(props) {
  const { onClose, open } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullScreen
      className={classes.Dialog}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogContent>
        <div className={classes.root}>
          <CircularProgress size={80} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function EnergyMix() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    refreshPage();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [data, setData] = useState([]);
  const [usage, setUsage] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const urlprefix = EnvSettings.LiveServiceUrl;

  const refreshPage = async () => {
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    let urls = [
      String(urlprefix + "/Energy/Energy/UnitMonthlyDemand"),
      String(urlprefix + "/Energy/Energy/UnitDailyDemand"),
      String(urlprefix + "/Energy/Energy/UnitAverageDemand"),
      String(urlprefix + "/Energy/Energy/EnergyMix"),
      String(urlprefix + "/Billing/Billing/GetBillData"),
      String(urlprefix + "/Energy/Energy/GetCO2Data"),
      String(urlprefix + "/Energy/Energy/CurrentUsage"),
    ];

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UnitNumber: userData[0].result.unitID, // change value to currentuser.unitnumber  once change is made
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    sessionStorage.removeItem("data");
    try {
      Promise.all(
        urls.map((url) => fetch(url, requestOptions).then((res) => res.json()))
      ).then((json) => {
        sessionStorage.setItem("data", JSON.stringify(json));
        handleClose();
      });
    } catch (error) {
      console.error(error);
    }
    setRefresh(!refresh);
  };
  let unchanged = true;

  useEffect(async () => {
    let json = await sessionStorage.getItem("data");

    let jsonData = JSON.parse(json);
    let mix = {
      battery_usage_kwh: jsonData.mix_battery,
      grid_usage_kwh: jsonData.mix_grid,
      solar_usage_kwh: jsonData.mix_solar,
    };

    setUsage(jsonData.mix_current);
    var a = [
      { title: "solar", value: mix.solar_usage_kwh },
      { title: "battery", value: mix.battery_usage_kwh },
      { title: "grid", value: mix.grid_usage_kwh },
    ];

	let solar = 0
	let battery = 0
	let grid = 0
	
	if(mix.solar_usage_kwh > 0){
		solar = mix.solar_usage_kwh
	}
	if(mix.battery_usage_kwh > 0){
		battery = mix.battery_usage_kwh
	}
	if(mix.grid_usage_kwh > 0){
		grid = mix.grid_usage_kwh
	}

	const sum = solar + battery + grid;


	const solarPercent = (solar / sum) * 100;
	const batteryPercent = (battery / sum) * 100;
	const gridPercent = (grid / sum) * 100;

    var d = [];
    if (sum === 0) {
      d = [
        {
          title: "Solar",
          value: 0.0,
        },
        {
          title: "Battery",
          value: 0.0,
        },
        {
          title: "Grid",
          value: 0.0,
        },
      ];
    } else {
      d = [
        {
          title: "Solar",
		  value: solarPercent,
        },
        {
          title: "Battery",
		  value: batteryPercent,
        },
        {
          title: "Grid",
		  value: gridPercent,
        },
      ];
    }

    setData(d);
  }, [unchanged]);

  return (
    <div>
      <parts.ContentTop>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LargeBold>{(usage / 1000).toFixed(2)}</LargeBold>
          <BoldLowered>kWh</BoldLowered>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Regular color="grey">Your usage (last half hour)</Regular>
          <Info page="Mix" />
        </div>
      </parts.ContentTop>
      <parts.ContentMiddle title="Supplied By">
        <BarChart data={data} source="mix" />
      </parts.ContentMiddle>
      <parts.ContentBottom>
        {/* <div onClick={handleClickOpen} className="ContentLosenge">
          REFRESH
          <SimpleDialog open={open} onClose={handleClose} />
        </div> */}
      </parts.ContentBottom>
    </div>
  );
}
