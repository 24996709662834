import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Privacy from "../Resources/PDF/Privacy.pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function Test() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "grey",
      }}
    >
      <Document file={Privacy}>
        <Page pageNumber={1} />
        <Page pageNumber={2} />
        <Page pageNumber={3} />
        <Page pageNumber={4} />
        <Page pageNumber={5} />
        <Page pageNumber={6} />
        <Page pageNumber={7} />
        <Page pageNumber={8} />
      </Document>
      <p></p>
    </div>
  );
}
