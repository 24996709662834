import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { getAuth, EmailAuthProvider } from "firebase/auth";
import PropTypes from "prop-types";
import React, { useState } from "react";
import * as parts from "../../Atoms/Partitions";
import Dialogue from "../../Toolbox/Dialogue";
import { getUpper } from "../../Toolbox/Regex";
import SmartForm from "../../Toolbox/SmartForm";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		justifyContent: "center",
		height: "100%",
		alignItems: "center",
		"& > * + *": {
			marginLeft: theme.spacing(2),
		},
	},
	Dialog: {
		opacity: 0.8,
	},
}));

function SimpleDialog(props) {
	const { onClose, open } = props;
	const classes = useStyles();

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog
			fullScreen
			className={classes.Dialog}
			onClose={handleClose}
			aria-labelledby='simple-dialog-title'
			open={open}
		>
			<DialogContent>
				<div className={classes.root}>
					<CircularProgress size={80} />
				</div>
			</DialogContent>
		</Dialog>
	);
}

SimpleDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};

export default function Profile() {
	const [value, setValue] = useState();

	const [open, setOpen] = useState(false);

	let rawData = JSON.parse(sessionStorage.getItem("userData"));
	let data = {
		firstName: rawData.firstName,
		lastName: rawData.lastName,
		email: rawData.email,
		telephone: rawData.telephone,
		development: rawData.development,
		address: rawData.address,
		moveInDate: rawData.moveInDate,
	};
	let UserDetails = [];
	let FormDefault = [];
	let [page, setPage] = useState("view");

	const excludedFields = [
		"EMAIL",
		"UNIT ID",
		"DEVELOPMENT",
		"development",
		"STRIPE ID",
	];

	for (let i in data) {
		if (i !== "unitID" && i !== "stripeID") {
			UserDetails.push({ title: getUpper(i), value: data[i] });
			FormDefault.push({ title: i, value: data[i] });
		} else continue;
	}
	const [formData, setFormData] = useState(FormDefault);

	const handleClose = () => {
		setOpen(false);
	};

	const setCase = (p) => {
		if (p === "view") {
			setPage("update");
		} else if (p === "update") {
			setPage("view");
		}
	};

	const handleResult = (res) => {
		let nameArr = [];
		let newArr = [...res];
		for (let i in data) {
			nameArr.push({ title: i });
		}
		for (var x = 0; x < res.length; x++) {
			newArr[x].title = nameArr[x].title;
			newArr[x].value = res[x].value;
		}
		setFormData(newArr);
	};

	const renderButton = () => {
		return (
			<parts.ContentBottom>
				<div className='ContentLosenge'>SAVE</div>
			</parts.ContentBottom>
		);
	};

	const getUserData = async (i) => {
		let userUrl = [
			"https://us-central1-snrg-api.cloudfunctions.net/ConnectAdmin/ConnectAdmin/GetUserProfile",
		];
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			email: i,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "manual",
		};

		//THE ABSOLUTE DON FOR FETCHING MULTIPLE APIs
		try {
			Promise.all(
				userUrl.map((url) =>
					fetch(url, requestOptions).then((res) => res.json())
				)
			).then((json) => {
				sessionStorage.setItem("userData", JSON.stringify(json));
			});
		} catch (error) {
			console.error(error);
		}
	};

	const renewDetails = (res) => {
		getUserData();
		setCase("update");
	};

	const handleConfirm = () => {
		console.log(value);
		const updateDetails = () => {
			setOpen(true);
			var data = {};
			for (var i = 0; i < formData.length; i++) {
				data[`${formData[i].title}`] = formData[i].value;
			}

			var config = {
				method: "post",
				url: "https://us-central1-snrg-api.cloudfunctions.net/ConnectAdmin/ConnectAdmin/UpdateUserProfileAndAddress",
				headers: {
					"Content-Type": "application/json",
				},
				data: data,
			};

			axios(config)
				.then(function (response) {
					console.log(JSON.stringify(response.data));
					setOpen(false);
					renewDetails(data);
				})
				.catch(function (error) {
					console.log(error);
				});
		};

		const auth = getAuth();
		let user = auth.currentUser;
		const credential = EmailAuthProvider.credential(user.email, value);
		user
			.reauthenticateWithCredential(credential)
			.then(() => {
				console.log("success");
				updateDetails();
			})
			.catch((error) => {
				console.log("error: " + error);
			});
	};

	const renderContent = () => {
		return (
			<div
				style={{
					height: "50vh",
					width: "80vw",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					textAlign: "center",
					paddingTop: "2vh",
				}}
			>
				<DialogTitle id='simple-dialog-title'>
					Update you profile details
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Please enter your password to update your profile details
					</DialogContentText>
					<input
						placeholder='Password'
						type='password'
						className='Input'
						onChange={(e) => {
							setValue(e.target.value);
						}}
					></input>
					<div className='DialogButton' onClick={handleConfirm}>
						UPDATE
					</div>
				</DialogContent>
			</div>
		);
	};

	switch (page) {
		case "view":
			return (
				<div>
					<parts.ContentMiddlePlus>
						{UserDetails.map((i) => (
							<div key={i.title} className='Card'>
								<div
									style={{
										margin: "1vh",
										fontSize: "1.5vh",
										fontWeight: "400",
									}}
								>
									{i.title}
								</div>
								<div
									style={{ margin: "1vh", fontSize: "2vh", fontWeight: "400" }}
								>
									{i.value}
								</div>
							</div>
						))}
					</parts.ContentMiddlePlus>
					<parts.ContentBottom>
						<div className='ContentLosenge' onClick={() => setCase(page)}>
							UPDATE
						</div>
					</parts.ContentBottom>
				</div>
			);
		case "update":
			return (
				<div>
					<SimpleDialog open={open} onClose={handleClose} />
					<parts.ContentMiddlePlus>
						<SmartForm
							data={UserDetails}
							exclusions={excludedFields}
							handleResult={handleResult}
						/>
						<div
							onClick={() => setCase(page)}
							style={{
								marginTop: "5vh",
								color: "dodgerblue",
								cursor: "pointer",
							}}
						>
							Go Back
						</div>
					</parts.ContentMiddlePlus>
					<Dialogue
						button={renderButton()}
						content={renderContent()}
						action={handleConfirm}
					/>
				</div>
			);
		default:
			return;
	}
}
