import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";
import React, { useState } from "react";

function SimpleDialog(props) {
  const { onClose, open, content, action, actionContent } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {content}
      <DialogActions>
        <Button onClick={action} color="primary">
          {actionContent}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const Dialogue = ({ button, content, action, actionContent }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleAction = () => {
    setOpen(false);
    action();
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{button}</div>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        content={content}
        actionContent={actionContent}
        action={handleAction}
      />
    </div>
  );
};
export default Dialogue;
