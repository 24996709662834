import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as icons from "../../Resources/icons/svg-gray/icons";
import {
	default as HeaderLogo,
	default as Logo,
} from "../../Resources/LOGO.png";
import {
	default as Background,
	default as BG,
} from "../../Resources/SNRG Background.png";
import Payments from "./Payments";
import Profile from "./Profile";
import Password from "./Password";
import Documents from "./Documents";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function Account() {
	const initialState = () => {
		if (sessionStorage.getItem("lastPage") === "payments") {
			return "documents";
		} else return "profile";
	};

	let history = useHistory();
	const [content, setContent] = useState(initialState);
	const activeCol = "#D62E6E";
	const inactiveCol = "#8D90A7";

	const chooseInitialColor = (val) => {
		if (val === true) {
			if (sessionStorage.getItem("lastPage") === "payments") {
				return activeCol;
			} else return inactiveCol;
		} else {
			if (sessionStorage.getItem("lastPage") === "payments") {
				return inactiveCol;
			} else return activeCol;
		}
	};

	const [profileCol, setProfileCol] = useState(chooseInitialColor(false));
	const [paymentCol, setPaymentCol] = useState(inactiveCol);
	const [passwordCol, setPasswordCol] = useState(inactiveCol);
	const [documentsCol, setDocumentsCol] = useState(chooseInitialColor(true));
	const MenuItems = [
		{ name: "Energy" },
		{ name: "Account" },
		{ name: "Support" },
		{ name: "Signout" },
	];

	const showContent = () => {
		if (content === "profile") return <Profile />;
		else if (content === "payment") return <Payments />;
		else if (content === "password") return <Password />;
		else if (content === "documents") return <Documents />;
		else return;
	};

	const chooseContent = (e) => {
		if (e === "profile") {
			setContent("profile");
			document.getElementById("profile").style.color = activeCol;
			document.getElementById("payment").style.color = inactiveCol;
			document.getElementById("password").style.color = inactiveCol;
			document.getElementById("documents").style.color = inactiveCol;
			setProfileCol(activeCol);
			setPaymentCol(inactiveCol);
			setPasswordCol(inactiveCol);
			setDocumentsCol(inactiveCol);
		} else if (e === "payment") {
			setContent("payment");
			document.getElementById("profile").style.color = inactiveCol;
			document.getElementById("payment").style.color = activeCol;
			document.getElementById("password").style.color = inactiveCol;
			document.getElementById("documents").style.color = inactiveCol;
			setProfileCol(inactiveCol);
			setPaymentCol(activeCol);
			setPasswordCol(inactiveCol);
			setDocumentsCol(inactiveCol);
		} else if (e === "password") {
			setContent("password");
			document.getElementById("profile").style.color = inactiveCol;
			document.getElementById("payment").style.color = inactiveCol;
			document.getElementById("password").style.color = activeCol;
			document.getElementById("documents").style.color = inactiveCol;
			setProfileCol(inactiveCol);
			setPaymentCol(inactiveCol);
			setPasswordCol(activeCol);
			setDocumentsCol(inactiveCol);
		} else if (e === "documents") {
			setContent("documents");
			document.getElementById("profile").style.color = inactiveCol;
			document.getElementById("payment").style.color = inactiveCol;
			document.getElementById("password").style.color = inactiveCol;
			document.getElementById("documents").style.color = activeCol;
			setProfileCol(inactiveCol);
			setPaymentCol(inactiveCol);
			setPasswordCol(inactiveCol);
			setDocumentsCol(activeCol);
		}
	};

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleMenuItem = (n) => {
		if (n === "Account") {
			handleClose();
		} else if (n === "Energy") {
			history.push("/Energy");
		} else if (n === "Signout") {
			history.push("/Signout");
		} else if (n === "Support") {
			history.push("/Support");
		}
	};

	return (
		<div className='EnergyContainer'>
			<img src={BG} className='BGHeader' alt='Bg' height={80} width={400} />

			{/* HEADER */}
			<div className='EnergyHeader'>
				<div className='HeaderLeft'>
					<img src={HeaderLogo} height='16' width='65' alt='logo'></img>
				</div>
				<div className='HeaderMiddle'>ACCOUNT</div>
				<div className='HeaderRight' onClick={handleClickOpen}>
					MENU
				</div>
			</div>
			{/* CONTENT */}
			<div className='EnergyContent'>{showContent()}</div>
			{/* FOOTER */}
			<div className='EnergyFooter'>
				<div
					className='FooterButtons'
					id='profile'
					style={{ color: chooseInitialColor(false) }}
					onClick={() => {
						chooseContent("profile");
					}}
				>
					<icons.profile col={profileCol} />
					<div style={{ marginTop: 10, fontWeight: "400" }}>Profile</div>
				</div>
				<div
					className='FooterButtons'
					id='payment'
					onClick={() => {
						chooseContent("payment");
					}}
				>
					<icons.payment col={paymentCol} />
					<div style={{ marginTop: 10, fontWeight: "400" }}>Payment</div>
				</div>
				<div
					className='FooterButtons'
					id='password'
					onClick={() => {
						chooseContent("password");
					}}
				>
					<icons.password col={passwordCol} />
					<div style={{ marginTop: 10, fontWeight: "400" }}>Password</div>
				</div>
				<div
					className='FooterButtons'
					id='documents'
					style={{ color: chooseInitialColor(true) }}
					onClick={() => {
						chooseContent("documents");
					}}
				>
					<icons.documents col={documentsCol} />
					<div style={{ marginTop: 10, fontWeight: "400" }}>Docs</div>
				</div>
			</div>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<div className='Login'>
					<img src={Background} className='LoginBG' alt='BG' height={2000} />
					<img src={Logo} className='Logo3' alt='Logo' />
					<div className='MenuContainer'>
						{MenuItems.map((item) => (
							<div
								key={item.name}
								onClick={() => handleMenuItem(item.name)}
								className='MenuItem'
							>
								{item.name}
							</div>
						))}
						<div onClick={handleClose} style={{ color: "white", margin: 20 }}>
							Close
						</div>
					</div>
				</div>
			</Dialog>
		</div>
	);
}
