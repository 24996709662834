import React from "react";
import { useState } from "react";
import Logo from "../../Resources/LOGO.png";
import { useHistory } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getAuth } from "firebase/auth";
import Background from "../../Resources/SNRG Background.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		justifyContent: "center",
		height: "100%",
		alignItems: "center",
	},
	Dialog: {
		fontFamily: "Roboto",
		opacity: 0.95,
	},
}));

function SimpleDialog(props) {
	const [submitted, setSubmitted] = React.useState(false);
	const [value, setValue] = React.useState();
	const { onClose, open } = props;
	const classes = useStyles();

	const handleClose = () => {
		setSubmitted(false);
		onClose();
	};

	//Forgotten Password
	const handleClick = () => {
		setSubmitted(true);
		const auth = getAuth();
		var user = sessionStorage.getItem("user");
		return auth.sendPasswordResetEmail(user);
	};

	const renderConfirmation = () => {
		if (submitted === true) {
			return (
				<div
					style={{
						textAlign: "center",
						display: "flex",
						flexDirection: "column",
						height: "100%",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					If the Email is correct you will receive a password reset to your
					inbox.
					<br />
					<br />
					<div
						style={{ color: "dodgerblue", cursor: "pointer" }}
						onClick={handleClose}
					>
						CLOSE
					</div>
				</div>
			);
		} else {
			return (
				<div
					className={classes.root}
					style={{
						display: "flex",
						flexDirection: "column",
						textAlign: "center",
					}}
				>
					Please enter your email address:
					<input
						placeholder='Email'
						type='text'
						className='Input'
						style={{ maxWidth: 400, marginTop: "4vh" }}
						onChange={(e) => {
							setValue(e.target.value);
						}}
					/>
					<div
						style={{ marginTop: "5vh", color: "dodgerblue", cursor: "pointer" }}
						onClick={handleClick}
					>
						SUBMIT
					</div>
				</div>
			);
		}
	};

	return (
		<Dialog
			fullScreen
			className={classes.Dialog}
			onClose={handleClose}
			aria-labelledby='simple-dialog-title'
			open={open}
		>
			<DialogContent>{renderConfirmation()}</DialogContent>
		</Dialog>
	);
}

SimpleDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};

export default function Login() {
	let history = useHistory();

	const [open, setOpen] = React.useState(false);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [logo, setLogo] = useState("Logo");
	const [form, setForm] = useState("LoginForm");

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};
	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	//Login Check
	function RegisterWithEmailAndPassword(email, password) {
		const auth = getAuth();
		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in
				const user = userCredential.user;
				// You can use the user object here if necessary
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				if (errorMessage != null) {
					alert(errorCode + ":" + errorMessage);
				}
			});
	}

	const handleSubmit = () => {
		RegisterWithEmailAndPassword(email, password);
		setForm("LoginForm2");
		setLogo("Logo2");
		setTimeout(() => {
			history.push("/");
		}, 1000);
	};
	const handleClose = () => {
		setOpen(false);
	};
	return (
		<div className='Login'>
			<SimpleDialog open={open} onClose={handleClose} />

			<img src={Background} className='LoginBG' alt='BG' height={2000} />
			<img src={Logo} className={logo} alt='Logo' />
			<form className={form}>
				<br />
				<input
					placeholder='Email'
					type='text'
					id='email'
					name='email'
					value={email}
					onChange={handleEmailChange}
					className='LoginInput'
				></input>
				<br />
				<br />
				<input
					placeholder='Password'
					className='LoginInput'
					type='password'
					id='password'
					name='password'
					value={password}
					onChange={handlePasswordChange}
				></input>
				<br />
				<br />
				<br />
				<div className='SubmitButton' onClick={handleSubmit}>
					<p>LOG IN</p>
				</div>
				<br />
				<br />
				<div
					style={{ cursor: "pointer", fontSize: "1.5vh" }}
					onClick={() => setOpen(true)}
				>
					Forgotten Password?
				</div>
			</form>
			{/* {/* <div className={form}>
        <h1 style={{"font-family": "Roboto"}}>Coming Soon
        </h1></div> */}
		</div>
	);
}
