import React from "react";

export default function BarChart(props) {
	let data = props.data;
	let source = props.source;
	var nums = [];
	for (var i = 0; i < data.length; i++) {
		nums.push(data[i].value);
	}
	let values = [];
	if (source === "mix") {
		let sum = nums.reduce(function (a, b) {
			return a + b;
		}, 0);
		for (var j = 0; j < nums.length; j++) {
			values.push((nums[j] / sum) * 100);
		}
	} else {
		if (Math.max(...nums) <= 1) {
			for (var k = 0; k < nums.length; k++) {
				values.push(nums[k]);
			}
		} else {
			for (var l = 0; l < nums.length; l++) {
				values.push((nums[l] / Math.max(...nums)) * 100);
			}
		}
	}
	let endData = [];
	for (var m = 0; m < data.length; m++) {
		endData.push({
			title: data[m].title,
			value: data[m].value,
			percent: values[m],
		});
	}
	const setWidth = (i) => {
		if (isNaN(i)) {
			return `25%`;
		} else {
			if (i < 20) {
				return `25%`;
			} else {
				return `${i}%`;
			}
		}
	};
	const renderItem = (val) => {
		if (source === "bills") {
			return "£" + val.toFixed(2);
		} else if (source === "mix") {
			return val.toFixed(2) + "%";
		} else return val.toFixed(2);
	};

	const setOverflow = () => {
		if (source === "usage") {
			return "scroll";
		} else return "hidden";
	};

	return (
		<div className='margins'>
			{endData.map((i) => (
				<div
					key={i.title}
					className='graphContainer'
					style={{ overflow: setOverflow() }}
				>
					<div className='outerDivider'>{i.title}</div>
					<div className='centralDivider'>
						<div className='barContainer'>
							<div className='innerBar' style={{ width: setWidth(i.percent) }}>
								<div style={{ marginLeft: "1.5vw" }}>{renderItem(i.value)}</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}
