import { SubTitle } from "../Atoms/Fonts";

const ContentTop = (props) => {
  return <div className="ContentTop">{props.children}</div>;
};

const ContentMiddle = (props) => {
  let title = props.title;
  return (
    <div>
      <div className="ContentHeader">
        <SubTitle>{title}</SubTitle>
      </div>
      <div className="ContentMiddle">{props.children}</div>
    </div>
  );
};

const ContentBottom = (props) => {
  return <div className="ContentBottom">{props.children}</div>;
};
const ContentMiddlePlus = (props) => {
  return <div className="ContentMiddlePlus">{props.children}</div>;
};
const ContentFull = (props) => {
  return <div className="ContentFull">{props.children}</div>;
};

export {
  ContentTop,
  ContentMiddle,
  ContentBottom,
  ContentMiddlePlus,
  ContentFull,
};
